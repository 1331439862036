<template>
  <v-theme-provider light id="mvmdoc">
    <v-card
      v-if="editedItem.id > 0 || editedItem.id == -1"
      elevation="4"
      :key="kdoc"
    >
      <v-toolbar
        :disabled="save_disable"
        dense
        floating
        class="mt-n2 ml-n2"
        color="#73DDFF"
      >
        <v-row>
          <v-col cols="12" sd="12" md="12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  @click="solder_mvm"
                  v-if="
                    solder &&
                    editedItem.statut_id == '11' &&
                    !edit &&
                    editedItem.decision_ret == 2 &&
                    editedItem.ttc_reste > 0 &&
                    editedItem.replace == '0'
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                >
                  <v-icon left> mdi-cancel </v-icon>
                  Solder
                </v-btn>
              </template>
              <span>Solder ce mouvement</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  @click="facturer"
                  v-if="
                    editedItem.areg == false &&
                    editedItem.ttc_paye == 0 &&
                    editedItem.ttc_reste > 0 &&
                    ((mvm_type == 1 && $store.state.auth.includes('02014')) ||
                      (mvm_type == 2 && $store.state.auth.includes('02015')) ||
                      (mvm_type == 3 && $store.state.auth.includes('02012')) ||
                      (mvm_type == 23 && $store.state.auth.includes('02100')) ||
                      (mvm_type == 4 && $store.state.auth.includes('02013')) ||
                      (mvm_type == 17 && $store.state.auth.includes('02021')) ||
                      $store.state.isadmin) &&
                    (mvm_type == 1 ||
                      mvm_type == 3 ||
                      mvm_type == 23 ||
                      ((mvm_type == 2 || mvm_type == 4) &&
                        editedItem.decision_ret == 2) ||
                      mvm_type == 17) &&
                    editedItem.statut_id == '11' &&
                    !edit &&
                    editedItem.replace == '0'
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                >
                  <v-icon left> mdi-plus </v-icon>
                  Facturer
                </v-btn>
              </template>
              <span> ajouter a une nouvelle facture </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  @click="showpaye = true"
                  v-if="
                    paye_bl &&
                    editedItem.mont_fact < editedItem.mont_ht &&
                    editedItem.statut_id == '11' &&
                    (mvm_type == 1 ||
                      mvm_type == 17 ||
                      mvm_type == 3 ||
                      ((mvm_type == 2 || mvm_type == 4) &&
                        editedItem.decision_ret != null)) &&
                    !edit
                  "
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon left> mdi-list </v-icon>
                  Paiement
                </v-btn>
              </template>
              <span>Paiements effectués </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  @click="valider_mvm"
                  v-if="
                    valider &&
                    editedItem.statut_id == '10' &&
                    detail_list.length > 0 &&
                    !edit
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon> mdi-check-bold </v-icon>
                  Valider
                </v-btn>
              </template>
              <span>Valider ce mouvement</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  @click="deleteMvm"
                  v-if="
                    editer &&
                    editedItem.statut_id == '10' &&
                    detail_list.length == 0 &&
                    !edit
                  "
                  :disabled="editedItem.nbdocs || save_disable"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon> mdi-delete </v-icon>Supprimer</v-btn
                >
              </template>
              <span>Supprimer le document</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  @click="modifier('m')"
                  v-if="editer && editedItem.statut_id == '10' && !edit"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon left> mdi-pencil </v-icon>
                  Modifier
                </v-btn>
              </template>
              <span>Editer pour modification</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  @click="itemsOpen"
                  v-if="
                    editer &&
                    editedItem.statut_id == '10' &&
                    detail_list.length > 0 &&
                    (mvm_type == 5 || mvm_type == 7 || mvm_type == 15) &&
                    !edit
                  "
                  :disabled="save_disable"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon> mdi-list-box-outline </v-icon>Items</v-btn
                >
              </template>
              <span>Ajouter Article Par Liste</span>
            </v-tooltip>
            <v-menu
              v-model="showMenu2"
              :close-on-content-click="true"
              transition="fab-transition"
              offset-y
              v-if="
                editedItem.decision_ret == null &&
                editer &&
                editedItem.statut_id == '11' &&
                ((mvm_type == 2 &&
                  ($store.state.auth.includes('05008') ||
                    $store.state.isadmin)) ||
                  (mvm_type == 4 &&
                    ($store.state.auth.includes('05010') ||
                      $store.state.isadmin))) &&
                !edit
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon left> mdi-pencil </v-icon>
                  Traitement
                </v-btn>
              </template>
              <v-list min-width="150">
                <v-list-item-group
                  v-model="editedItem.decision_ret"
                  color="primary"
                >
                  <v-list-item>
                    <v-list-item-title @click="create_cmd"
                      >A remplacer (créer une commande de
                      remplacement)</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    v-if="
                      editedItem.ttc_paye_liv == 0 &&
                      editedItem.facture_liv == 1
                    "
                  >
                    <v-list-item-title @click="update_mvm('a')"
                      >facture avoir</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    :disabled="
                      editedItem.facture_liv == 1 || editedItem.ttc_paye_liv > 0
                    "
                  >
                    <v-list-item-title @click="update_mvm('f')"
                      >Déduire de l'expédition originale N°
                      {{
                        editedItem.nobon_liv +
                        " du " +
                        datefr(editedItem.mvm_date_liv) +
                        (editedItem.facture_liv == 1
                          ? " (Expédition Facturée)"
                          : editedItem.ttc_paye_liv > 0 &&
                            ($store.state.auth.includes("05021") ||
                              $store.state.isadmin)
                          ? " (Enc. Existe)"
                          : "")
                      }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-menu
              v-model="showMenu2"
              :close-on-content-click="true"
              transition="fab-transition"
              offset-y
              v-if="
                mvm_type == 3 &&
                ($store.state.auth.includes('01027') || $store.state.isadmin) &&
                editedItem.statut_id != '10' &&
                !edit
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon left> mdi-printer </v-icon>
                </v-btn>
              </template>
              <v-list min-width="150">
                <v-list-item-group
                  v-model="editedItem.print_type"
                  color="primary"
                >
                  <v-list-item>
                    <v-list-item-title @click="printMvm2('v')"
                      >Valorisé</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title @click="printMvm2('q')"
                      >Quantitatif</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title @click="print_list_glob()"
                      >Liste Colisage</v-list-item-title
                    >
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  @click="cancel"
                  v-if="editer && edit"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon> mdi-close </v-icon> Annuler</v-btn
                >
              </template>
              <span>Annuler les Modifications</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  @click="save"
                  v-if="editer && edit"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon left> mdi-content-save-outline </v-icon>
                  Enregistrer
                </v-btn>
              </template>
              <span>Enregistrer les modifications</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-1"
                  @click="printMvm"
                  v-if="
                    (mvm_type != 3 ||
                      (mvm_type == 3 &&
                        !(
                          $store.state.auth.includes('01027') ||
                          $store.state.isadmin
                        ))) &&
                    editedItem.statut_id != '10' &&
                    !edit
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon> mdi-printer </v-icon></v-btn
                >
              </template>
              <span>Imprimer le {{ title }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-if="
                    editedItem.id > 0 &&
                    !edit &&
                    ($store.state.auth.includes('01061') ||
                      $store.state.isadmin)
                  "
                  @click="OpenDocList"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-paperclip </v-icon>
                </v-btn>
              </template>
              <span>Documents Attachés</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="unlock"
                  v-if="
                    ($store.state.auth.includes('01087') ||
                      $store.state.isadmin) &&
                    editedItem.mont_fact == 0 &&
                    (editedItem.statut_id == '11' ||
                      editedItem.statut_id == '26' ||
                      editedItem.statut_id == '27') &&
                    !edit &&
                    can_unlock &&
                    editedItem.decision_ret == null
                  "
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon> mdi-lock-open-variant </v-icon>
                </v-btn>
              </template>
              <span>Déverrouiller</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-if="
                    editedItem.id > 0 &&
                    ($store.state.auth.includes('01060') ||
                      $store.state.isadmin) &&
                    !edit
                  "
                  @click.stop="drawer = !drawer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-clipboard-text-clock-outline </v-icon>
                </v-btn>
              </template>
              <span>Historique Document</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-progress-linear
        v-if="progress || data_loading"
        indeterminate
        color="light-green darken-4"
        height="10"
        striped
      ></v-progress-linear>
      <v-card class="px-4 scroll" max-height="850">
        <v-card-text>
          <v-row>
            <v-col cols="12" sd="4" md="4" align-self="start">
              <img :src="logo" alt="Company Logo" />
            </v-col>

            <v-col cols="12" sd="8" md="8">
              <v-alert
                v-model="alert"
                :type="type_alert"
                class="multi-line"
                width="380"
                elevation="4"
                >{{ message }}</v-alert
              >
            </v-col>
            <!-- <v-col
            cols="12"
            sd="9"
            md="9"
            align-self="center"
            class="text-center"
          >
            <h1>{{ myname }}</h1>
          </v-col> -->
          </v-row>

          <!-- <v-row class="d-flex justify-space-around">
          <span>{{ "I.F. " + myif }}</span>
          <span>{{ "A.I. " + myart }}</span>
          <span>{{ "R.C. " + myrc }}</span>
          <span>
            {{
              editedItem.compte == null ? "" : "C.B. " + editedItem.compte
            }}</span
          >
        </v-row> -->
          <v-divider></v-divider>
          <v-row>
            <v-col></v-col>
          </v-row>
          <v-form ref="form" v-model="valid" lazy-validation class="mt-6">
            <v-row>
              <v-col cols="12" sd="6" md="6" lg="6">
                <v-row>
                  <v-col cols="12" sm="6" md="6" v-if="edit && mvm_type == 5">
                    <v-autocomplete
                      ref="depot_from"
                      v-model="editedItem.depot_from"
                      item-text="label"
                      item-value="id"
                      :items="depots_from"
                      label="Depot Source"
                      :readonly="
                        !modify || editedItem.mvmdetails.length > 0 || !edit
                      "
                      :rules="[(v) => !!v || 'Depot obligatoire']"
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="11"
                    sm="11"
                    md="11"
                    lg="11"
                    v-if="edit && mvm_type == 5"
                  >
                    <v-autocomplete
                      v-model="editedItem.dem_id"
                      :item-value="'id'"
                      :items="alldemandes"
                      :filter="customFilter"
                      :label="'Demande de Transfert'"
                      :clearable="modify && edit"
                      dense
                      :readonly="!modify || !edit"
                      @input="cs++"
                    >
                      <template v-slot:selection="{ item }">
                        <!-- HTML that describe how select should render selected items -->
                        {{
                          item.no_da +
                          (item.date_da ? "-" + datefr(item.date_da) : "") +
                          (item.agent_name ? " - " + item.agent_name : "")
                        }}
                      </template>
                      <template v-slot:item="{ item }">
                        <!-- HTML that describe how select should render selected items -->
                        {{
                          item.no_da +
                          (item.date_da ? "-" + datefr(item.date_da) : "") +
                          (item.agent_name ? " - " + item.agent_name : "")
                        }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sd="11" md="11">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        v-if="
                          edit &&
                          (mvm_type <= 4 ||
                            mvm_type == 17 ||
                            mvm_type == 23 ||
                            mvm_type == 19)
                        "
                      >
                        <cursorselect
                          :Qsearch="tierscursor"
                          :Qresp="'tiers_cursor'"
                          :value="editedItem.tier_id"
                          :text_fields="[
                            'code_client',
                            'code_fournisseur',
                            'nom',
                          ]"
                          :variableadd="variableadd"
                          :whereadd="
                            (mvm_type == 2 || mvm_type == 4) && !retourcomex
                              ? whereadd
                              : null
                          "
                          :item_text="'tier_name'"
                          @change="tier_change"
                          :label="tier_label"
                          :readonly="!modify || detail_list.length > 0 || !edit"
                          :key="cs"
                        >
                        </cursorselect>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        v-if="
                          edit &&
                          editedItem.id < 0 &&
                          mvm_type == 1 &&
                          $store.state.depots.length > 1 &&
                          $route.params.cmd
                        "
                      >
                        <v-autocomplete
                          v-model="editedItem.depot_id"
                          :items="$store.state.depots"
                          item-text="label"
                          item-value="id"
                          label="Depot par defaut"
                          :rules="[(v) => !!v || 'Depot obligatoire']"
                          outlined
                          dense
                          :readonly="!modify"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        v-if="edit && (mvm_type == 2 || mvm_type == 4)"
                      >
                        <v-autocomplete
                          v-model="editedItem.motif_ret"
                          :items="[
                            { id: '1', label: 'Excès de commande' },
                            { id: '2', label: 'Non conformités' },
                          ]"
                          item-text="label"
                          item-value="id"
                          label="Motif retour"
                          :rules="[(v) => !!v || 'Motif retour obligatoire']"
                          outlined
                          dense
                          :readonly="!modify"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="2"
                        md="4"
                        v-if="
                          editedItem.tier_id &&
                          edit &&
                          (mvm_type == 1 || mvm_type == 3 || mvm_type == 23) &&
                          ret_exist
                        "
                      >
                        <v-checkbox
                          v-model="editedItem.replace"
                          :label="'Remplacement retour'"
                          :readonly="!edit"
                          dense
                          @change="replace_change"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        v-if="
                          edit &&
                          (mvm_type == 15 ||
                            mvm_type == 16 ||
                            mvm_type == 18 ||
                            mvm_type == 21 ||
                            mvm_type == 22)
                        "
                      >
                        <v-autocomplete
                          :items="tiers_list"
                          v-model="editedItem.tier_id"
                          :item-value="'id'"
                          :filter="customFilter"
                          :rules="[(v) => !!v || tier_label + ' obligatoire']"
                          :label="tier_label"
                          @change="tier_change"
                          :loading="loading"
                          hide-no-data
                          hide-selected
                          :readonly="!modify || detail_list.length > 0 || !edit"
                        >
                          <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            {{
                              mvm_type == 1 || mvm_type == 2 || mvm_type == 17
                                ? data.item.code_fournisseur +
                                  "-" +
                                  data.item.nom
                                : mvm_type == 4 ||
                                  mvm_type == 3 ||
                                  mvm_type == 23 ||
                                  mvm_type == 19
                                ? data.item.code_client + "-" + data.item.nom
                                : data.item.matricule + "-" + data.item.nom
                            }}
                          </template>
                          <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            {{
                              mvm_type == 1 || mvm_type == 2 || mvm_type == 17
                                ? data.item.code_fournisseur +
                                  "-" +
                                  data.item.nom
                                : mvm_type == 4 ||
                                  mvm_type == 3 ||
                                  mvm_type == 23 ||
                                  mvm_type == 19
                                ? data.item.code_client + "-" + data.item.nom
                                : data.item.matricule + "-" + data.item.nom
                            }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    class="text-left"
                    v-if="
                      !edit &&
                      mvm_type != 5 &&
                      mvm_type != 7 &&
                      mvm_type != 8 &&
                      !rcpt_prod
                    "
                  >
                    {{ tier_title }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="8"
                    v-if="
                      !edit &&
                      mvm_type != 5 &&
                      mvm_type != 7 &&
                      mvm_type != 8 &&
                      !rcpt_prod
                    "
                  >
                    <strong
                      ><h3>{{ editedItem.tier_name }}</h3></strong
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="
                      !edit &&
                      mvm_type != 5 &&
                      mvm_type != 7 &&
                      mvm_type != 8 &&
                      !rcpt_prod
                    "
                  >
                    {{
                      mvm_type == 1 ||
                      mvm_type == 2 ||
                      mvm_type == 3 ||
                      mvm_type == 23 ||
                      mvm_type == 4 ||
                      mvm_type == 17 ||
                      mvm_type == 19
                        ? "Code"
                        : "Matricule"
                    }}
                  </v-col>
                  <v-col
                    cols="12"
                    sd="2"
                    md="8"
                    v-if="
                      !edit &&
                      mvm_type != 5 &&
                      mvm_type != 7 &&
                      mvm_type != 8 &&
                      !rcpt_prod
                    "
                  >
                    <a @click="gotier" v-if="consult_tier && mvm_type < 5">
                      {{ editedItem.tier_code }}
                    </a>
                    <div v-else>
                      {{ editedItem.tier_code }}
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="
                      !edit &&
                      (mvm_type <= 4 || mvm_type == 17 || mvm_type == 19)
                    "
                  >
                    Adresse</v-col
                  >
                  <v-col
                    cols="12"
                    sd="2"
                    md="8"
                    v-if="
                      !edit &&
                      (mvm_type <= 4 || mvm_type == 17 || mvm_type == 19)
                    "
                  >
                    {{ editedItem.adresse }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="
                      !edit &&
                      (mvm_type <= 4 || mvm_type == 17 || mvm_type == 19)
                    "
                  >
                  </v-col>
                  <v-col
                    cols="12"
                    sd="2"
                    md="8"
                    v-if="
                      !edit &&
                      (mvm_type <= 4 || mvm_type == 17 || mvm_type == 19)
                    "
                  >
                    {{ departement }}
                  </v-col>

                  <v-col cols="12" sd="2" md="4" v-if="!edit && mvm_type == 3"
                    >Adresse de Livraison</v-col
                  >
                  <v-col cols="12" sd="2" md="8" v-if="!edit && mvm_type == 3">
                    {{ editedItem.adresse_liv ? editedItem.adresse_liv : "" }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="!edit && mvm_type == 3"
                  ></v-col>
                  <v-col cols="12" sd="2" md="8" v-if="!edit && mvm_type == 3">
                    {{
                      (editedItem.departement_liv
                        ? editedItem.departement_liv
                        : "") +
                      "-" +
                      (editedItem.ville_liv ? editedItem.ville_liv : "")
                    }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sd="2" md="6" lg="6">
                <v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sd="12" md="12">
                      <h2>{{ formTitle }}</h2>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="2"
                      md="4"
                      v-if="
                        editedItem.tier_id &&
                        editedItem.country_id == 13 &&
                        edit &&
                        ((mvm_type == 1 &&
                          ($store.state.auth.includes('01088') ||
                            $store.state.isadmin)) ||
                          ((mvm_type == 3 ||
                            (mvm_type == 19 &&
                              this.$store.state.options[0].rcpt_wo == 0)) &&
                            ($store.state.auth.includes('01092') ||
                              $store.state.isadmin)))
                      "
                    >
                      <v-checkbox
                        v-model="editedItem.areg"
                        :label="
                          (mvm_type == 1 || mvm_type == 19
                            ? 'Reception '
                            : 'Livraison ') + 'sans BC'
                        "
                        :readonly="!edit"
                        dense
                        @change="cmpt++"
                      ></v-checkbox>
                    </v-col>

                    <!-- <v-col
                      cols="12"
                      sd="12"
                      md="12"
                      lg="12"
                      v-if="
                        !edit &&
                        (mvm_type == 1 ||
                          mvm_type == 3 ||
                          mvm_type == 17 )
                      "
                    >
                      <strong>
                        Commande N° :
                        {{
                          (editedItem.nocmd ? editedItem.nocmd : "") +
                          (editedItem.cmd_label
                            ? "-" + editedItem.cmd_label
                            : "") +
                          (editedItem.sign_date
                            ? " du " + datefr(editedItem.sign_date)
                            : "")
                        }}</strong
                      >
                    </v-col> -->
                    <v-col
                      cols="12"
                      sd="12"
                      md="12"
                      lg="12"
                      v-if="!edit && (mvm_type == 2 || mvm_type == 4)"
                    >
                      <strong>
                        {{ mvm_type == 2 ? "Reception" : "Expedition" }} N° :
                        {{
                          (editedItem.nobon_liv ? editedItem.nobon_liv : "") +
                          (editedItem.mvm_date_liv
                            ? " du " + datefr(editedItem.mvm_date_liv)
                            : "")
                        }}</strong
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      sd="12"
                      md="12"
                      lg="12"
                      v-if="!edit && mvm_type == 5"
                    >
                      <strong>
                        Depot Source :
                        {{
                          editedItem.depot_from_name
                            ? editedItem.depot_from_name
                            : ""
                        }}</strong
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      sd="12"
                      md="12"
                      lg="12"
                      v-if="!edit && mvm_type == 5 && editedItem.dem_id"
                    >
                      <strong>
                        Selon la demande N° :
                        {{
                          (editedItem.no_da ? editedItem.no_da : "") +
                          (editedItem.date_da
                            ? " du " + datefr(editedItem.date_da)
                            : "")
                        }}</strong
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="!edit && retours.length > 0"
                    >
                      <strong> Retours Associcés : </strong>
                      <p
                        v-for="ret in retours.filter(
                          (elm) => elm.decision_ret == 3 && elm.statut_id == 11
                        )"
                        :key="ret.id"
                        dense
                      >
                        {{ ret.label }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      sd="12"
                      md="12"
                      lg="12"
                      v-if="
                        !edit &&
                        (mvm_type == 1 || mvm_type == 17 || mvm_type == 19) &&
                        editedItem.country_id != 13
                      "
                    >
                      <strong>
                        Dossier N° :
                        {{
                          (editedItem.nodossier ? editedItem.nodossier : "") +
                          (editedItem.date_dossier
                            ? " du " +
                              datefr(editedItem.date_dossier, false, false)
                            : "")
                        }}</strong
                      >
                    </v-col>

                    <v-col cols="12" sd="12" md="12" lg="12" v-if="!edit">
                      <p>
                        Effectué Par :
                        {{
                          (editedItem.preneur ? editedItem.preneur : "") +
                          (editedItem.opr_date
                            ? " le " + datefr(editedItem.opr_date, false, false)
                            : "")
                        }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      sd="12"
                      md="12"
                      lg="12"
                      v-if="
                        !edit && mvm_type == 7 && editedItem.orders.length > 0
                      "
                    >
                      <v-card flat v-if="editedItem.orders.length > 0">
                        <v-card-title>Ordres de Fabrication :</v-card-title>
                        <v-card-subtitle>
                          <v-col cols="12" sm="12" md="12">
                            <v-row
                              v-for="order in editedItem.orders"
                              :key="order.of_id"
                              dense
                              no-gutters
                            >
                              <v-col cols="12" sm="12" md="12">
                                {{ order.no_order }}
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                    <v-col
                      cols="12"
                      sd="12"
                      md="12"
                      lg="12"
                      v-if="
                        editedItem.mont_fact > 0 &&
                        editedItem.nomvm &&
                        (mvm_type == 3 || mvm_type == 23) &&
                        !edit
                      "
                    >
                      <strong> BL N° : {{ editedItem.nomvm }}</strong>
                    </v-col>

                    <v-col cols="12" sd="12" md="12" lg="12">
                      <strong v-if="!edit">
                        Ref. : {{ editedItem.mvm_ref }}</strong
                      >
                    </v-col>
                  </v-row>
                  <v-col cols="12" sd="12" md="12" lg="12">
                    <v-row dense v-if="edit">
                      <v-col cols="12" sd="6" md="6" class="text-left">
                        <v-text-field
                          autocomplete="off"
                          dense
                          class="inputPrice"
                          v-model="editedItem.mvm_ref"
                          :readonly="!edit"
                          label="Ref."
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        v-if="mvm_type == 2 || mvm_type == 4"
                      >
                        <v-autocomplete
                          :items="mvms_list"
                          v-model="editedItem.liv_id"
                          item-value="id"
                          :label="mvm_type == 2 ? 'Reception' : 'Expedition'"
                          :filter="customFilter"
                          :readonly="!modify || detail_list.length > 0 || !edit"
                          :rules="[
                            (v) =>
                              !!v ||
                              (mvm_type == 2 ? 'Reception' : 'Expedition') +
                                ' obligatoire',
                          ]"
                          dense
                          clearable
                          small-chips
                        >
                          <template v-slot:selection="{ item }">
                            {{ item.nobon + " du " + datefr(item.mvm_date) }}
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="
                                  item.nobon +
                                  ' du ' +
                                  datefr(item.mvm_date) +
                                  (item.mont_fact > 0
                                    ? ' (Facturée)'
                                    : item.ttc_paye > 0 &&
                                      ($store.state.auth.includes('05021') ||
                                        $store.state.isadmin)
                                    ? ' (Enc. Existe)'
                                    : ' (Non Facturée)')
                                "
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <!-- <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        v-if="
                          ((mvm_type == 1 || mvm_type == 3 || mvm_type == 17) &&
                            editedItem.country_id == 13) 
                        "
                      >
                        <v-autocomplete
                          :items="cmds"
                          v-model="editedItem.cmd_id"
                          item-value="id"
                          :label="'Commande'"
                          :filter="customFilter"
                          :readonly="
                            !modify || detail_list.length > 0 || !edit
                          "
                          :rules="[
                            (v) =>
                              !!v ||
                              (mvm_type == 1 &&
                                ($store.state.auth.includes('01088') ||
                                  $store.state.isadmin)) ||
                              (mvm_type == 3 &&
                                ($store.state.auth.includes('01092') ||
                                  $store.state.isadmin)) ||
                              'Commande obligatoire',
                          ]"
                          @change="cmd_change"
                          dense
                          small-chips
                          clearable
                        >
                          <template v-slot:selection="{ item }">
                            
                            {{
                              item.nocmd +
                              " - " +
                              (item.label ? item.label : item.tier_name) +
                              " du " +
                              datefr(item.sign_date)
                            }}
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="
                                  item.nocmd +
                                  ' - ' +
                                  (item.label ? item.label : '') +
                                  ' du ' +
                                  datefr(item.sign_date)
                                "
                              ></v-list-item-title>                                                         
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col> -->

                      <v-col cols="12" sm="6" md="7">
                        <v-autocomplete
                          :items="agents"
                          v-model="editedItem.agent_id"
                          :item-value="'id'"
                          :item-text="
                            (item) => item.matricule + ' - ' + item.nom
                          "
                          :filter="customFilter"
                          dense
                          :rules="[
                            (v) => !!v || 'Responsable opération obligatoire',
                          ]"
                          :label="'Effectué par'"
                          :readonly="!modify || !edit || ag_redonly"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sd="4" md="5">
                        <datepicker
                          label="
                          Date Opération
                        "
                          v-model="editedItem.opr_date"
                          :rules="[(v) => !!v || 'Date Opération obligatoire']"
                          :date_min="
                            (mvm_type <= 4 ||
                              mvm_type == 17 ||
                              mvm_type == 19) &&
                            editedItem.sign_date
                              ? editedItem.sign_date
                              : min_date
                          "
                          :edit="edit"
                        ></datepicker>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        v-if="
                          mvm_type == 1 &&
                          editedItem.country_id != 13 &&
                          editedItem.country_id != -1
                        "
                      >
                        <v-autocomplete
                          :items="imports"
                          v-model="editedItem.dossier_id"
                          item-value="id"
                          :label="'Arrivage'"
                          :rules="[(v) => !!v || 'Arrivage obligatoire']"
                          :readonly="!modify || detail_list.length > 0 || !edit"
                          @change="dossier_change"
                          @click:clear="editedItem.cmd_id = null"
                          dense
                          small-chips
                          clearable
                        >
                          <template v-slot:selection="{ item }">
                            <!-- HTML that describe how select should render selected items -->
                            {{
                              item.nodossier +
                              " du " +
                              datefr(item.date_dossier)
                            }}
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="
                                  item.nodossier +
                                  ' du ' +
                                  datefr(item.date_dossier)
                                "
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-html="'Ref. : ' + item.ref"
                                v-if="item.ref"
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                v-html="
                                  'Fournisseur : ' +
                                  item.code_fournisseur +
                                  '-' +
                                  item.tier_name
                                "
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                v-html="
                                  'Commande N° : ' +
                                  item.nocmd +
                                  ' du ' +
                                  datefr(item.date_cmd)
                                "
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="12" v-if="mvm_type == 7">
                        <v-autocomplete
                          v-model="orders"
                          :item-text="
                            (item) =>
                              (item.no_order ? item.no_order + ' - ' : '') +
                              (item.cmd_label ? item.cmd_label : '') +
                              (item.label ? ' - ' + item.label : '')
                          "
                          :item-value="'id'"
                          :items="open_orders"
                          :readonly="detail_list.length > 0"
                          multiple
                          chips
                          label="Ordres de Fabrication"
                          :rules="[
                            (v) =>
                              orders.length > 0 ||
                              'Ordre de Fabrication obligatoire',
                          ]"
                          dense
                        ></v-autocomplete>
                      </v-col>

                      <v-col
                        cols="12"
                        sd="4"
                        md="7"
                        class="text-left"
                        v-if="
                          (mvm_type < 5 || mvm_type == 17) &&
                          paye_bl &&
                          editedItem.country_id == 13
                        "
                      >
                        <v-row>
                          <v-col cols="12" sd="6" md="9">
                            <datepicker
                              label="
                          Délai de Paiement
                        "
                              v-model="duedate"
                              :edit="edit"
                              @change="nbjour"
                              :key="kdd"
                              :date_min="editedItem.mvm_date"
                              :date_max="null"
                            ></datepicker>
                          </v-col>
                          <v-col
                            cols="12"
                            sd="6"
                            md="3"
                            v-if="editedItem.country_id == 13"
                          >
                            <v-text-field
                              autocomplete="off"
                              type="number"
                              dense
                              class="inputPrice"
                              v-model.number="editedItem.delai_paye"
                              :label="'Jours'"
                              :readonly="!edit"
                              @input="addDays"
                              hide-spin-buttons
                              @focus="$event.target.select()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" sm="6" md="5" v-if="mvm_type == 3">
                        <v-autocomplete
                          :items="adresses"
                          v-model="editedItem.adress_id"
                          :item-text="
                            (item) =>
                              item.label +
                              ' - ' +
                              item.departement_name +
                              ' - ' +
                              item.ville_name
                          "
                          item-value="id"
                          :label="'Adresse de livraison'"
                          clearable
                          dense
                          :readonly="!edit"
                          @input="cmpt++"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sd="12" md="12" class="text-left">
                        <v-text-field
                          dense
                          v-model="editedItem.comment"
                          label="Commentaire"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12" sd="12" md="12" v-if="!edit">
              <listitems
                :list="detail_list"
                :title="'Produits'"
                :headers="products_headers"
                :filename="'Produits'"
                :sheetname="'Produits'"
                @open="OpenMvmForm"
                @btn_click="OpenMvmForm"
                :master="true"
                :showedit="false"
                :key="klist"
                :add="
                  editer &&
                  editedItem.statut_id == '10' &&
                  editedItem.id > 0 &&
                  (mvm_type != 5 || (mvm_type == 5 && !editedItem.dem_id))
                "
                :Update="
                  editer && editedItem.statut_id == '10' && editedItem.id > 0
                "
                :del="
                  editer &&
                  editedItem.statut_id == '10' &&
                  editedItem.id > 0 &&
                  (mvm_type != 5 || (mvm_type == 5 && !editedItem.dem_id))
                "
                :elevation="0"
                :loading_del="loading_del"
                @hrf="print_list"
                @delete="deleteItem"
                :ipg="ipg"
                @options="send_option"
              >
              </listitems>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="!edit" class="pa-3">
            <v-col cols="12" sd="9" md="9" lg="9">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="mvm_type == 3 || mvm_type == 23"
                >
                  DELAI DE PAIEMENT

                  {{
                    editedItem.delai_paye
                      ? editedItem.delai_paye +
                        " jours (Avant le " +
                        datefr(editedItem.date_limite) +
                        ")"
                      : ""
                  }}
                </v-col>

                <v-col cols="12" sd="6" md="12" class="text-left">
                  Comment : {{ editedItem.comment }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="editedItem.areg"
                >
                  NB: {{ mvm_type == 1 ? "Reception " : "Livraison " }} sans Bon
                  de commande
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="editedItem.replace"
                >
                  NB: {{ mvm_type == 1 ? "Reception " : "Livraison " }} de
                  remplacement de retour
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="mvm_type == 2 || mvm_type == 4"
                >
                  NB: Retour
                  {{
                    editedItem.decision_ret == null
                      ? " a traiter"
                      : editedItem.decision_ret == 1
                      ? " a remplacer"
                      : editedItem.decision_ret == 2
                      ? " a établir facture avoir"
                      : " modifiant facture " +
                        (mvm_type == 2 ? "reception" : "expedition")
                  }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Etablit Par : {{ editedItem.contact }} le
                  {{ datefr(editedItem.mvm_date) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              sd="3"
              md="3"
              lg="3"
              v-if="
                !rcpt_prod &&
                !mvm_stock &&
                ($store.state.auth.includes('01027') || $store.state.isadmin)
              "
            >
              <v-row no-gutters>
                <v-col cols="12" sd="6" md="4"> Total H.T : </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold nowrap text-right pa-1"
                >
                  <h3>{{ numberWithSpace(decimalDigits(subTotal)) }}</h3>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="remise > 0">
                <v-col cols="12" sd="6" md="4"> Remise : </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold nowrap text-right pa-1"
                >
                  {{ numberWithSpace(decimalDigits(remise)) }}
                </v-col>
              </v-row>
              <div v-if="mvm_type != 15">
                <v-row v-for="(tax, taux) in taxTotal" :key="taux" no-gutters>
                  <v-col cols="12" sd="6" md="4">
                    T.V.A {{ tax.taux }} %
                  </v-col>
                  <v-col cols="12" sd="6" md="8" class="text-right pa-1">
                    {{
                      numberWithSpace(
                        decimalDigits(
                          parseFloat(((tax.ht * tax.taux) / 100).toFixed(2))
                        )
                      )
                    }}
                  </v-col>
                </v-row>
                <v-row v-if="taxTotal.length > 1" no-gutters>
                  <v-col cols="12" sd="6" md="4"> Total T.V.A </v-col>
                  <v-col cols="12" sd="6" md="8" class="text-right pa-1">
                    {{ numberWithSpace(decimalDigits(sumtaxTotal())) }}
                  </v-col>
                </v-row>
                <v-row v-if="subTotal > 0 && sumtaxTotal() == 0" no-gutters>
                  <v-col cols="12" sd="6" md="4"> T.V.A</v-col>
                  <v-col
                    cols="12"
                    sd="6"
                    md="8"
                    class="text-bold text-right pa-1"
                  >
                    EXONORE
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" sd="6" md="4"> Total T.T.C </v-col>
                  <v-col
                    cols="12"
                    sd="6"
                    md="8"
                    class="text-bold nowrap text-right pa-1"
                  >
                    <h3>{{ numberWithSpace(decimalDigits(grandTotal)) }}</h3>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <template>
            <confirmdialog
              ref="confirm"
              :is_motif="is_motif"
              @motif="get_motif"
            />
          </template>

          <v-snackbar
            v-model="snackbar"
            :timeout="snackbar_timeout"
            top
            :color="snackbar_color"
          >
            {{ snackbar_text }}
          </v-snackbar>

          <component
            :is="!isRcptClosed ? 'mvmrcptform' : null"
            :item="mvmdetail"
            :items="detail_list"
            :cmddetails="mvm_type == 19 ? cmds_details : cmds_details_tier"
            :tier_articles="
              mvm_type == 18 || mvm_type == 19 ? articles_fab : tier_articles
            "
            :importdetails="importdetails"
            :key="rkey"
            :showForm="!isRcptClosed"
            :attributes="attributes"
            :boxs="boxs"
            @close="closeRcptForm"
            :mvm_type="mvm_type"
            :modify="modify"
            :units="units"
            :mvm="mvm"
            :areg="editedItem.areg"
            :isloading="isloading"
          >
          </component>
          <component
            :is="!isLivClosed ? 'mvmlivform' : null"
            :item="mvmdetail"
            :items="detail_list"
            :cmddetails="cmds_details_tier"
            :mvm_type="mvm_type"
            :key="lkey"
            :showForm="!isLivClosed"
            :attributes="attributes"
            @close="closeLivForm"
            :modify="modify"
            :client_products="client_products"
            :mvm="mvm"
            :units="units"
            :areg="editedItem.areg"
            :price_correct="price_correct"
            :isloading="isloading"
          >
          </component>
          <component
            :is="!isRetExtClosed ? 'mvmretextform' : null"
            :item="mvmdetail"
            :items="detail_list"
            :articles="mvmlivs"
            :mvm_type="mvm_type"
            :key="retextkey"
            :showForm="!isRetExtClosed"
            :attributes="attributes"
            :boxs="boxs"
            :mvm="mvm"
            @close="closeRetExtForm"
            :modify="modify"
            :units="units"
          >
          </component>
          <component
            :is="!isRetIntClosed ? 'mvmretintform' : null"
            :item="mvmdetail"
            :items="detail_list"
            :articles="articles"
            :boxs="boxs"
            :mvm_type="mvm_type"
            :key="retintkey"
            :showForm="!isRetIntClosed"
            :attributes="attributes"
            :mvm="mvm"
            @close="closeRetIntForm"
            :modify="modify"
            :units="units"
          >
          </component>
          <component
            :is="!isProdClosed ? 'mvmdetailprod' : null"
            :item="mvmdetail"
            :items="detail_list"
            :encours_list="encours_list"
            :readonly="editedItem.statut_id != '10'"
            :key="pkey"
            :showForm="!isProdClosed"
            :attributes="attributes"
            :boxs="boxs"
            @close="closeProdForm"
            :of_id="of_id"
            @of_id="send_of_id"
            :units="units"
            :mvm_type="mvm_type"
          >
          </component>
          <component
            :is="!isAddItemClosed ? 'additemform' : null"
            :articles="detail_list"
            :depot_from="parseInt(editedItem.depot_from)"
            :key="aikey"
            :showForm="!isAddItemClosed"
            :max_field="mvm_type == 5 ? 'qte_dem' : 'qte_cmd'"
            @close="isAddItemClosed = true"
          >
          </component>

          <component
            :is="!isTransfertClosed ? 'mvmtransfertform' : null"
            :mvm="mvm"
            :mvm_type="mvm_type"
            :item="mvmdetail"
            :items="detail_list"
            :key="tkey"
            :attributes="attributes"
            :units="units"
            :boxs="boxs"
            :products="products"
            :showForm="!isTransfertClosed"
            @close="closeTransfertForm"
          >
          </component>
          <component
            :is="!isTransfertDemClosed ? 'mvmtransfertform_dem' : null"
            :mvm="mvm"
            :mvm_type="mvm_type"
            :item="mvmdetail"
            :items="detail_list"
            :key="tkeyf"
            :attributes="attributes"
            :units="units"
            :boxs="boxs"
            :products="products"
            :showForm="!isTransfertDemClosed"
            @close="closeTransfertDemForm"
          >
          </component>
          <component
            :is="showpaye ? 'factpayelist' : null"
            :fact="mvm"
            :showpaye="showpaye"
            :paye_list="paye_list"
            @add="add_paye"
            :add="
              editedItem.paye_statut == '0' &&
              editedItem.mont_fact == 0 &&
              editedItem.statut_id == '11' &&
              ((mvm_type == 1 && $store.state.auth.includes('02007')) ||
                (mvm_type == 2 && $store.state.auth.includes('02008')) ||
                (mvm_type == 3 && $store.state.auth.includes('02009')) ||
                (mvm_type == 23 && $store.state.auth.includes('02100')) ||
                (mvm_type == 4 && $store.state.auth.includes('02010')) ||
                (mvm_type == 17 && $store.state.auth.includes('02021')) ||
                $store.state.isadmin)
            "
            :key="fp"
            @close="ClosePayeForm"
            :facture="false"
          >
          </component>

          <component
            :is="!isDocListClose ? 'filelist' : null"
            :item="editedItem"
            :showform="!isDocListClose"
            :key="fdocl"
            @close="isDocListClose = true"
            :editer="editer"
            :doc_type="3"
            :auth="auth"
          >
          </component>

          <timeline
            :document_type="3"
            :document_id="parseInt(editedItem.id)"
            v-model="drawer"
            :operations="operations"
            :doc_type="'Mouvement'"
            :doc_code="editedItem.nobon"
          >
          </timeline>
        </v-card-text>
      </v-card>
    </v-card>
  </v-theme-provider>
</template>
<script>
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
import DELETE_MVM_DETAIL from "../graphql/Mouvment/DELETE_MVM_DETAIL.gql";
import DELETE_MVM from "../graphql/Mouvment/DELETE_MVM.gql";
import SIT_CLIENT from "../graphql/Tier/SIT_CLIENT.gql";
import MVM_UPDATE from "../graphql/Mouvment/MVM_UPDATE.gql";
import UPDATE_MVM from "../graphql/Mouvment/UPDATE_MVM.gql";

import DEPOTS_ARTICLES from "../graphql/Depot/DEPOTS_ARTICLES.gql";
//import BOXS_ARTICLES from "../graphql/Depot/BOXS_ARTICLES.gql";
import PRODUCT_LIST_MVM from "../graphql/Product/PRODUCT_LIST_MVM.gql";
//import ALLPAYE from "../graphql/Facture/ALLPAYE.gql";
//import ALLCMDS from "../graphql/Commande/ALLCMDS.gql";
import ALLMVM from "../graphql/Mouvment/ALLMVM.gql";
import PRODUCTION_LIST_MVM from "../graphql/Production/PRODUCTION_LIST_MVM.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";
//import ARTICLES_LIVS from "../graphql/Mouvment/ARTICLES_LIVS.gql";
import ARTICLES_LIVS_AGENT from "../graphql/Mouvment/ARTICLES_LIVS_AGENT.gql";
//import ARTICLES_LIVS_MACHINE from "../graphql/Mouvment/ARTICLES_LIVS_MACHINE.gql";
import CREATE_CMD from "../graphql/Commande/CREATE_CMD.gql";
import CREATE_CMD_DETAIL from "../graphql/Proforma/CREATE_CMD_DETAIL.gql";
import MVMS from "../graphql/Product/MVMS.gql";
import UPDATE_MODIF from "../graphql/UPDATE_MODIF.gql";
import UPDATE_ARTICLES from "../graphql/Product/UPDATE_ARTICLES.gql";
import ALLDETAILSCMDS_LIGHT from "../graphql/Mouvment/ALLDETAILSCMDS_LIGHT.gql";
import ALLARTICLES_DEPOT from "../graphql/Depot/ALLARTICLES_DEPOT.gql";
import { liste } from "print/liste.js";
import { listeglob } from "print/liste_glob.js";
import {
  myLogo,
  myName,
  myIF,
  myRC,
  myART,
  grt,
  myBL,
  retourcomex,
  ctl_mvmdate,
  unlock_mvm,
} from "print/data.js";
import { doc_template } from "print/template.js";
import { datefr } from "@/utils/functions.js";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    mvmtransfertform: () => import("../components/MvmTransfertForm.vue"),
    mvmtransfertform_dem: () =>
      import("../components/MvmTransfertForm_dem.vue"),
    mvmdetailprod: () => import("./MvmDetailProd.vue"),
    mvmrcptform: () => import("./MvmRcptForm.vue"),
    mvmlivform: () => import("./MvmLivForm.vue"),
    mvmretextform: () => import("./MvmRetExtForm.vue"),
    mvmretintform: () => import("./MvmRetIntForm.vue"),
    additemform: () => import("./AddItemForm.vue"),
    factpayelist: () => import("./FactPayeList.vue"),
    filelist: () => import("./FileList.vue"),
    listitems: () => import("./ListItems.vue"),
    datepicker: () => import("./DatePicker.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
    timeline: () => import("./Timeline.vue"),
  },
  props: {
    mvm: Object,
    mvm_type: Number,
    dossiers_list: Array,
    open_orders: Array,
    encours_list: Array,
    agents: Array,
    mvms_type: Array,
    editer: Boolean,
    valider: Boolean,
    solder: Boolean,
    data_loading: Boolean,
    attributes: Array,
    of_id: String,
    adress_list: Array,
    articles_fab: Array,
    units: Array,
    boxs: Array,
    alldemandes: Array,
    edition: { type: Boolean, default: false },
    ipg: { type: Number, default: 10 },
  },
  data() {
    return {
      message: "",
      save_disable: false,
      orders: [],
      cs: 3000,
      alert: false,
      type_alert: "error",
      order: {},
      loading_del: false,
      datepicker2: false,
      drawer: null,
      consult_tier: false,
      modify: false,
      paye_bl: false,
      progress: false,
      loading: false,
      duedate: null,
      valid: true,
      showMenu2: false,
      numberRule: (v) => {
        if (!v || (!isNaN(parseFloat(v)) && v >= 0 && v <= 100)) return true;
        return "Nombre doit être entre 0 and 100";
      },
      ret_exist: false,
      is_motif: false,
      act_save: true,
      isDetailClosed: true,
      isProdClosed: true,
      isDocListClose: true,
      isTransfertClosed: true,
      isAddItemClosed: true,
      isTransfertDemClosed: true,
      isRcptClosed: true,
      isLivClosed: true,
      isRetExtClosed: true,
      isRetIntClosed: true,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",

      client: "Client information",
      mc: 200,
      kdd: 1600,
      edit: false,
      dkey: 100,
      rkey: 1200,
      lkey: 1300,
      tkey: 1400,
      tkeyf: 1450,
      retextkey: 1500,
      retintkey: 1600,
      pkey: 400,
      aikey: 452,
      fdocl: 1000,
      showpaye: false,
      cmddetail: {},
      changed: false,
      mvmdetail: {},
      readonly: true,
      old_detail: [],
      products_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
          index: 0,
        },

        {
          text: "Code",
          value: "code",
          selected: true,
          slot: "href",
          align: "left",
          index: 1,
        },
        {
          text: "Produit",
          value: "label",
          slot: null,
          selected: true,
          width: "300",
          description: "description",
          align: "left",
          index: 2,
        },
        {
          text: "Reception",
          value: "nobon_init",
          selected: false,
          hiden: true,
          align: "left",
          index: 3,
        },
        {
          text: "Depot",
          value: "depot_name",
          selected: true,
          hiden: true,
          align: "left",
          index: 4,
        },
        {
          text: "PU HT",
          value: "pu",
          slot: null,
          hiden: true,
          selected: true,
          index: 5,
        },
        {
          text: "Quantité",
          value: "qte",
          slot: "cur",
          align: "end",
          selected: true,
          dec: 2,
          edit: true,
          type: "qte",
          oblig: false,
          width: "150",
          sm: 12,
          md: 6,
          index: 6,
        },
        {
          text: "Unité",
          value: "unit",
          selected: true,
          index: 7,
        },
        {
          text: "Depot Source",
          value: "depot_from",
          selected: true,
          hiden: true,
          align: "left",
          index: 8,
        },
        {
          text: "Montant HT",
          value: "ht",
          slot: "cur",
          hiden: true,
          align: "end",
          width: "150",
          selected: true,
          index: 9,
        },
        {
          text: "Remise(%)",
          value: "remise",
          slot: "cur",
          hiden: true,
          align: "end",
          selected: true,
          index: 10,
        },
        {
          text: "Comment",
          value: "comment",
          selected: false,
          index: 11,
        },
        {
          text: "Colisage",
          value: "coliscount",
          slot: "href2",
          align: "end",
          dec: 0,
          hiden: true,
          selected: true,
          index: 12,
        },
        {
          text: "Cmd",
          value: "nocmd",
          selected: false,
          hiden: true,
          sm: 3,
          md: 3,
          index: 13,
        },
        {
          text: "N° Retour",
          value: "nobon_ret",
          selected: false,
          hiden: true,
          sm: 3,
          md: 3,
          index: 14,
        },
        {
          text: "Qte Dem.",
          value: "qte_dem",
          slot: "cur",
          selected: true,
          align: "end",
          hiden: true,
          index: 15,
        },
        {
          text: "",
          slot: "actions",
          selected: true,
          hiden: true,
          index: 16,
        },
      ],
      editedItem: {},
      cmds_details_tier: [],
      cmds_details: [],
      imports: [],
      tiers_list: [],
      articles: [],
      mvms_list: [],
      products: [],
      klist: 500,
      kdoc: 700,
      fp: 300,
      cmpt: 0,
      ag_redonly: false,
      min_date: null,
      option: "1",
      price_correct: false,
      defaultItem: {
        id: -1,
        status_name: "Brouillon",
        tier: {},
        statut_id: "10",
        mvmdetails: [],
        retours: [],
        docs: [],
        mvm_date: null,
        opr_date: null,
        tot_liv: 0,
        notes: "",
        country_id: -1,
      },
      cmds: [],
      adresses: [],
      isloading: false,
    };
  },

  async created() {
    if (
      myBL == "n" &&
      (this.$store.state.auth.includes("05021") || this.$store.state.isadmin)
    )
      this.paye_bl = true;
    this.products_headers[3].hiden =
      this.mvm_type != 2 && this.mvm_type != 4 && this.mvm_type != 16; //depot
    this.products_headers[3].text =
      this.mvm_type == 2
        ? "N° RECEPTION"
        : this.mvm_type == 4
        ? "N° EXPEDITION"
        : "N° SORTIE";
    this.products_headers[4].hiden = this.mvm_type == 17 || this.mvm_type == 23; //depot
    this.products_headers[5].hiden =
      this.rcpt_prod ||
      this.mvm_type == 5 ||
      this.mvm_type == 7 ||
      this.mvm_type == 8 ||
      !(this.$store.state.auth.includes("01027") || this.$store.state.isadmin); //pu

    //this.products_headers[8].hiden = this.mvm_type != 5; //depot_to
    this.products_headers[9].hiden =
      this.rcpt_prod ||
      this.mvm_type == 5 ||
      this.mvm_type == 7 ||
      this.mvm_type == 8 ||
      !(this.$store.state.auth.includes("01027") || this.$store.state.isadmin); //ht

    //this.products_headers[12].hiden = this.editedItem.statut_id == "10";

    this.products_headers[13].hiden = this.mvm_type > 4 && this.mvm_type != 19;

    await this.get_tier_list();
    this.min_date = this.$store.state.exercice + "-01-01";
    if (this.mvm)
      if (this.mvm.id) {
        this.edit = this.edition;
        if (this.mvm.id > 0) {
          this.editedItem = Object.assign({}, this.mvm);

          this.products_headers[10].hiden =
            !this.existe_remise || this.rcpt_prod || this.mvm_stock;

          this.products_headers[12].hiden =
            this.detail_list.findIndex((elm) => elm.coliscount > 0) == -1;

          this.products_headers[14].hiden =
            (this.mvm_type > 4 && this.mvm_type != 19) ||
            this.editedItem.replace == 0;

          this.products_headers[16].hiden =
            this.editedItem.statut_id != "10" ||
            this.mvm_type == 19 ||
            this.mvm_type == 3 ||
            this.mvm_type == 15 ||
            this.mvm_type == 23;

          this.products_headers[15].hiden = !(
            this.mvm_type == 5 && this.editedItem.statut_id == "10"
          );

          this.cs++;
          if (this.mvm_type == 7) {
            this.option = this.editedItem.orders.length > 0 ? "1" : "2";
            this.orders = this.editedItem.orders.map((elm) => elm.of_id);
          }
          this.editedItem.areg =
            (this.$store.state.options[0].rcpt_wo == 1 &&
              this.mvm_type == 19) ||
            this.editedItem.areg == "1";
          this.editedItem.replace = this.editedItem.replace == "1";
          this.replace_change();
          if (this.editedItem.statut_id == "12" && this.editedItem.motif) {
            this.message =
              "Motif : " + (this.editedItem.motif ? this.editedItem.motif : "");
            this.alert = true;
          }
          if (this.editedItem.ttc_paye > 0 && this.editedItem.ttc_reste == 0) {
            this.message = "Reglée";
            this.type_alert = "success";
            this.dissm = false;
            this.alert = true;
          }
          if (
            this.editedItem.mont_fact > 0 &&
            (this.mvm_type == 1 || this.mvm_type == 3)
          ) {
            let s = "";
            this.editedItem.factures.forEach((element) => {
              s = s + element.nofact + "\n";
            });
            this.message = "Facturée sous N° " + s;
            this.type_alert = "success";
            this.dissm = false;
            this.alert = true;
          }
          if (
            (this.mvm_type == 2 || this.mvm_type == 4) &&
            this.editedItem.decision_ret != null
          ) {
            this.message =
              "Traitement retenu : " +
              (this.editedItem.decision_ret == 1
                ? " remplacement (commande Interne N° " +
                  this.editedItem.nocmd_ret +
                  ")"
                : this.editedItem.decision_ret == 2
                ? this.editedItem.nofact
                  ? "facture avoir N° " + this.editedItem.nofact
                  : " établir facture avoir"
                : this.editedItem.nofact
                ? " modifier facture N° " + this.editedItem.nofact
                : " à modifier expedition ");
            this.type_alert = "success";
            this.dissm = false;
            this.alert = true;
          }

          if (
            this.mvm_type == 2 ||
            this.mvm_type == 4 ||
            this.mvm_type == 16 ||
            this.mvm_type == 22
          )
            this.articles_livre();
          this.addDays();
          if (
            (this.mvm_type == 19 &&
              this.$store.state.options[0].rcpt_wo == 0) ||
            this.mvm_type == 1 ||
            this.mvm_type == 17 ||
            this.mvm_type == 3 ||
            this.mvm_type == 23
          ) {
            let w;
            if (this.mvm_type == 19)
              w = {
                AND: [
                  {
                    column: "CMD_TYPE",
                    operator: "IN",
                    value: [1, 3],
                  },
                  { column: "STATUT_ID", value: 7 },
                  { column: "TIER_ID", value: this.editedItem.tier_id },
                  { column: "REST_RCPT", operator: "GT", value: 0 },
                ],
              };
            else
              w = {
                AND: [
                  {
                    column: "CMD_TYPE",
                    operator: "IN",
                    value:
                      this.mvm_type == 1 || this.mvm_type == 17
                        ? [2, 4]
                        : [1, 3],
                  },
                  { column: "STATUT_ID", value: 7 },
                  { column: "CANCELED", value: 0 },
                  { column: "REST_LIV", operator: "GT", value: 0 },
                  { column: "TIER_ID", value: this.editedItem.tier_id },
                  {
                    column: "SERVICE",
                    value: this.mvm_type == 17 || this.mvm_type == 23 ? 1 : 0,
                  },
                ],
              };

            let v = {
              where: w,
              Kind:
                this.$store.state.auth.includes("03077") ||
                this.$store.state.isadmin
                  ? 1
                  : 0,
              expire: 1,
            };
            this.isloading = true;
            let r = await this.requette(ALLDETAILSCMDS_LIGHT, v);
            this.isloading = false;
            if (r) {
              this.cmds_details = r.alldetailscmds_light.filter(
                (elm) => elm.product_statut_id == 2
              );
            }
          }
          if (this.mvm_type == 5) {
            let v = {
              where: {
                AND: [
                  {
                    column: "DEPOT_ID",
                    value: this.editedItem.depot_from,
                  },
                  {
                    column: "STOCK_CMPT",
                    operator: "GT",
                    value: 0,
                  },
                  {
                    column: "LINKCOUNT",
                    value: 0,
                  },
                ],
              },
            };
            let r = await this.requette(ALLARTICLES_DEPOT, v);
            if (r) {
              this.products = r.allarticles_depot;
            }
          }
          this.tier_change(this.editedItem.tier_id);
        } else {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.opr_date = this.$store.state.today;
          this.editedItem.mvm_date = this.$store.state.today;
          this.editedItem.areg =
            this.$store.state.options[0].rcpt_wo == 1 && this.mvm_type == 19;
          if (this.mvm_type == 1 && this.$store.state.depots.length == 1)
            this.editedItem.depot_id = this.$store.state.depots[0].id;
          if (this.mvm.tier_id > 0) {
            this.editedItem.cmd_id = this.mvm.cmd_id;
            this.editedItem.tier_id = this.mvm.tier_id;
            this.tier_change(this.editedItem.tier_id);
          }
          if (this.rcpt_prod && this.mvm.of_id) {
            this.editedItem.of_id = this.mvm.of_id;
            this.save();
          }
        }
        if (this.$store.state.me.agent_id && !this.$store.state.isadmin) {
          if (this.mvm.id < 0)
            this.editedItem.agent_id = this.$store.state.me.agent_id;
          this.ag_redonly = true;
        }
      }

    if (this.option == 1) {
      this.price_correct =
        (this.$store.state.auth.includes("02027") ||
          this.$store.state.isadmin) &&
        this.mvm_type == 3 &&
        this.mvm.statut_id == "11" &&
        this.mvm.mont_fact == 0;
    }

    this.products_headers[5].slot = this.price_correct ? "href" : null;
    this.modify = this.editer && this.editedItem.statut_id == "10";
    this.readonly =
      this.editer &&
      (this.mvm_type == 3 || this.mvm_type == 4 || this.mvm_type == 23) &&
      this.editedItem.statut_id == "11" &&
      this.editedItem.mont_fact == 0;
    //if (this.readonly) this.products_headers[2].slot = "href";
    this.consult_tier =
      ((this.mvm_type == 3 || this.mvm_type == 4) &&
        this.$store.state.auth.includes("01030")) ||
      ((this.mvm_type == 1 || this.mvm_type == 2) &&
        this.$store.state.auth.includes("01029")) ||
      this.$store.state.isadmin;
  },

  methods: {
    async create_cmd() {
      this.showMenu2 = false;
      if (
        await this.$refs.confirm.open(
          "Confirmation !",
          "Vous êtes sur le point de créer un Bon de commande interne de remplacement?",
          { color: "orange darken-3" }
        )
      ) {
        this.save_disable = true;
        let v;
        // créer commande
        v = {
          cmd: {
            cmd_type: this.mvm_type == 2 ? 2 : 1,
            ret_id: this.editedItem.id,
            tier_id: this.editedItem.tier_id,
            date_cmd: this.$store.state.today,
            sign_date: this.$store.state.today,
            statut_id: 7,
            date_opr: this.$store.state.today,
            label:
              "Commande Interne de remplacement du retour N° " +
              this.editedItem.nobon +
              " du " +
              this.datefr(this.editedItem.mvm_date),
            currency_id: 114,
            source_id: 0,

            mont_ht: this.editedItem.mont_ht,
            mont_tva: this.editedItem.mont_tva,
            mont_ttc: this.editedItem.mont_ttc,

            modalite_id: this.editedItem.modalite_id,
            modepaiement: this.editedItem.modepaiement,

            create_uid: parseInt(this.$store.state.me.id),
            write_uid: parseInt(this.$store.state.me.id),
          },
        };
        let r = await this.maj(CREATE_CMD, v);
        if (r) {
          let cmd = r.createCmd;
          // créer detail commande
          let l = [];
          for (let index = 0; index < this.detail_list.length; index++) {
            const element = this.detail_list[index];
            l.push({
              pu: element.pu,
              qte: parseFloat(element.qte),
              cmd_id: r.createCmd.id,
              delai: element.delai,
              adress_id: element.adress_id,
              produit_id: element.produit_id,
              article_id: element.article_id,
              fk_unit: element.fk_unit,
              tva_tx: parseFloat(element.tva_tx),
              create_uid: this.$store.state.me.id,
              write_uid: this.$store.state.me.id,
            });
          }

          v = {
            input: l,
            cmd_id: r.createCmd.id,
          };
          let r1 = await this.maj(CREATE_CMD_DETAIL, v);
          if (r1) {
            this.editedItem.statut_id = "4";
            v = {
              mvm: {
                id: this.editedItem.id,
                decision_ret: 1,
              },
            };
            await this.maj(UPDATE_MVM, v);
            //actualiser les alertes
            this.save_disable = false;
          }
          this.$store.dispatch("Changed", true);
          this.$store.dispatch("Editing", false);
          if (this.mvm_type == 4)
            this.$router.push({
              name: "commandes_c",
              params: { cmd: cmd },
            });
          else
            this.$router.push({
              name: "commandes_f",
              params: { cmd: cmd },
            });
        }
      } else this.editedItem.decision_ret = null;
    },
    async update_mvm(e) {
      this.showMenu2 = false;
      let c = "transformer en facture avoir?";
      if (e == "f") c = "Deduire de la facture originale?";
      if (await this.$refs.confirm.open("Confirmation !", c)) {
        this.save_disable = true;
        let v = {
          mvm: {
            id: this.editedItem.id,
            decision_ret: e == "a" ? 2 : 3,
          },
        };
        await this.maj(UPDATE_MVM, v);
        this.$store.dispatch("Changed", true);
      } else this.editedItem.decision_ret = null;
    },

    send_option(item) {
      this.$emit("options", item);
    },
    dossier_change() {
      let i = this.imports.findIndex(
        (elm) => elm.id == this.editedItem.dossier_id
      );
      if (i >= 0) {
        this.editedItem.cmd_id = this.imports[i].cmd_id;
      }
    },
    async verif_modif(e) {
      this.alert = false;
      let ok = false;
      let v = {
        table: "mouvments",
        statut_id: "10",
        id: this.editedItem.id,
      };
      this.progress = true;
      let r = await this.maj(UPDATE_MODIF, v);
      this.progress = false;
      if (r) {
        let d = JSON.parse(r.UpdateModif);
        if (d.modif == 1) {
          this.alert = true;
          this.message =
            "Document ouvert en modification par " +
            d.name +
            " depuis le " +
            datefr(d.modif_date, true);
          this.type_alert = "warning";
        } else if (d.modif == 2 && e == "m") {
          this.alert = true;
          this.message = "Document Validé!! Actualiser vos données ";
          this.type_alert = "error";
        } else if (d.modif == -1) {
          this.alert = true;
          this.message = "Document introuvable ";
          this.type_alert = "error";
        } else {
          this.$store.dispatch("Document", v);
          this.$store.dispatch("Editing", true);
          ok = true;
        }
      }
      return ok;
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    async articles_livre() {
      // if (this.mvm_type == 2 || this.mvm_type == 4) {
      //   let v = {
      //     client_id: this.mvm_type == 4 ? this.editedItem.tier_id : null,
      //     fournis_id: this.mvm_type == 2 ? this.editedItem.tier_id : null,
      //   };
      //   let r = await this.requette(ARTICLES_LIVS, v);
      //   if (r) this.articles = r.articles_livres;
      // }
      if (this.mvm_type == 16 || this.mvm_type == 22) {
        let v = {
          agent_id: this.editedItem.tier_id,
        };
        let r = await this.requette(ARTICLES_LIVS_AGENT, v);
        if (r) this.articles = r.articles_livres_agent;
      }
    },

    async modifier(e) {
      if (await this.verif_modif(e)) {
        this.edit = true;
        this.$emit("edition", true);
        this.kdoc++;
      }
      this.save_disable = false;
    },
    async cancel() {
      let ok = true;

      if (this.cmpt > 2 || this.$route.params.cmd_id) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir annuler?"
          )
        ) {
          ok = true;
        } else this.update_modif();
      }
      if (ok) {
        //goto commandes
        if (this.$route.params.cmd) {
          await this.update_modif();
          this.$router.push({
            name: this.$route.params.name,
            params: {
              cmd: this.$route.params.cmd,
            },
          });
        } else {
          if (this.editedItem.id < 0) {
            this.editedItem.id = null;
            this.$emit("cancel", this.editedItem);
          }
          this.edit = false;
          this.$emit("edition", false);
          await this.update_modif();
          this.$store.dispatch("Changed", true);
          this.kdoc++;
        }
      }
    },
    async deleteMvm() {
      if (await this.verif_modif("m")) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer ce mouvement?"
          )
        ) {
          this.save_disable = true;
          let v = {
            id: this.mvm.id,
          };
          let r = await this.maj(DELETE_MVM, v, true);
          if (r) {
            this.$emit("delete", this.editedItem);
            this.editedItem = {};
            this.$store.dispatch("Editing", false);
          }
        } else this.update_modif();
      }
      this.save_disable = false;
    },
    async print_list_glob() {
      let colis_list = [];

      this.loading_liste = true;
      let v = {
        mvm_id: this.editedItem.id,
      };
      let r = await this.requette(PRODUCT_LIST_MVM, v);
      if (r) {
        colis_list = r.product_list_mvm;

        listeglob(this.$store, this.mvm, colis_list);
      }

      this.loading_liste = false;
    },
    async print_list(item) {
      let colis_list = [];
      this.loading_liste = true;
      let v = {
        mvm_detail_id: item.id,
      };
      let r = await this.requette(PRODUCT_LIST_MVM, v);
      if (r) {
        colis_list = r.product_list_mvm;
        liste(this.$store, this.mvm, item, colis_list, this.attributes);
      }

      this.loading_liste = false;
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.editedItem.id > 0) {
        let v = {
          mvm: {
            id: this.editedItem.id,
            modif_by: null,
            modif_date: null,
          },
        };
        await this.maj(UPDATE_MVM, v);
      }
    },
    closeProdForm() {
      this.update_modif();
      this.isProdClosed = true;
    },
    closeTransfertForm() {
      this.update_modif();
      this.isTransfertClosed = true;
    },
    closeTransfertDemForm() {
      this.update_modif();
      this.isTransfertDemClosed = true;
    },
    closeRcptForm() {
      this.update_modif();
      this.isRcptClosed = true;
    },
    closeLivForm() {
      this.update_modif();
      this.isLivClosed = true;
    },
    closeRetExtForm() {
      this.update_modif();
      this.isRetExtClosed = true;
    },
    closeRetIntForm() {
      this.update_modif();
      this.isRetIntClosed = true;
    },
    send_of_id() {
      this.$emit("of_id");
    },
    facturer() {
      this.save_disable = true;
      if (this.mvm_type == 1)
        this.$router.push({
          name: "facturations_f",
          params: { mvm: this.mvm, name: "receptions_e" },
        });
      if (this.mvm_type == 17)
        this.$router.push({
          name: "facturations_f",
          params: { mvm: this.mvm, name: "service_fait_a" },
        });
      if (this.mvm_type == 2)
        this.$router.push({
          name: "facturations_fa",
          params: { mvm: this.mvm, name: "retours_f" },
        });
      if (this.mvm_type == 3)
        this.$router.push({
          name: "facturations_c",
          params: { mvm: this.mvm, name: "livraisons" },
        });
      if (this.mvm_type == 23)
        this.$router.push({
          name: "facturations_c",
          params: { mvm: this.mvm, name: "service_fait_v" },
        });
      if (this.mvm_type == 4)
        this.$router.push({
          name: "facturations_ca",
          params: { mvm: this.mvm, name: "retours_c" },
        });
    },
    itemsOpen() {
      this.isAddItemClosed = false;
      this.aikey++;
    },
    add_paye() {
      this.mvm.facture = "0";
      this.mvm.modepaiement = "4";
      this.showpaye = false;
      if (this.mvm_type == 1)
        this.$router.push({
          name: "paiements",
          params: { document: this.mvm, name: "receptions_e" },
        });
      if (this.mvm_type == 17)
        this.$router.push({
          name: "paiements",
          params: { document: this.mvm, name: "service_fait_a" },
        });
      if (this.mvm_type == 2)
        this.$router.push({
          name: "encaissements",
          params: { document: this.mvm, name: "retours_f" },
        });
      if (this.mvm_type == 3)
        this.$router.push({
          name: "encaissements",
          params: { document: this.mvm, name: "livraisons" },
        });
      if (this.mvm_type == 23)
        this.$router.push({
          name: "encaissements",
          params: { document: this.mvm, name: "service_fait_v" },
        });
      if (this.mvm_type == 4)
        this.$router.push({
          name: "paiements",
          params: { document: this.mvm, name: "retours_c" },
        });
    },
    async cmd_change() {
      let i;
      if (this.editedItem.cmd_id > 0) this.editedItem.areg = false;
      this.editedItem.dossier_id = null;

      if (this.cmds_list)
        i = this.cmds_list.findIndex((elm) => elm.id == this.editedItem.cmd_id);
      if (i >= 0) {
        if (this.editedItem.id < 0)
          this.editedItem.sign_date = this.cmds_list[i].sign_date;
      }

      this.cmpt++;
    },
    customFilter(item, queryText) {
      let textlist = [];
      [
        "code_tier",
        "tier_name",
        "code_client",
        "code_fournisseur",
        "nom",
        "matricule",
        "nocmd",
        "label",
        "sign_date",
      ].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    gotier() {
      if (this.mvm_type == 3 || this.mvm_type == 4)
        this.$router.push({
          name: "clients",
          params: {
            mvm: this.mvm,
            tier_id: this.editedItem.tier_id,
            code_tier: this.editedItem.tier_code,
            tier_name: this.editedItem.tier_name,
            name: this.mvm_type == 3 ? "livraisons" : "retours_c",
          },
        });
      if (this.mvm_type == 1 || this.mvm_type == 2)
        this.$router.push({
          name: "fournisseurs",
          params: {
            mvm: this.mvm,
            tier_id: this.editedItem.tier_id,
            code_tier: this.editedItem.tier_code,
            tier_name: this.editedItem.tier_name,
            name: this.mvm_type == 1 ? "receptions_e" : "retours_f",
          },
        });
    },
    async tier_change(item, l) {
      let list = this.tiers_list;
      if (l) list = l;
      this.editedItem.tier_id = item;
      if (this.editedItem.id < 0 && !this.mvm.tier_id) {
        this.editedItem.country_id = null;
        if (this.mvm_type != 19) this.editedItem.cmd_id = null;
        this.$refs.form.resetValidation();
      }
      if (!this.editedItem.tier_id) this.editedItem.country_id = -1;
      let i = list.findIndex((elm) => elm.id == this.editedItem.tier_id);
      if (i >= 0) {
        this.editedItem.country_id = list[i].country_id;
        if (this.editedItem.id < 0) {
          this.editedItem.delai_paye = list[i].delai_paye;
          this.addDays();
        }
      }
      if (this.mvm_type == 2 || this.mvm_type == 4) {
        let v = {
          where: {
            AND: [
              { column: "TIER_ID", value: this.editedItem.tier_id },
              { column: "MVM_TYPE", value: this.mvm_type == 2 ? 1 : 3 },
              { column: "STATUT_ID", value: 11 },
            ],
          },
        };
        let r = await this.requette(ALLMVM, v);
        if (r) {
          this.mvms_list = r.allmvm;
        }
      }
      this.cmds_details_tier = this.cmds_details.filter(
        (elm) =>
          elm.tier_id == this.editedItem.tier_id &&
          (this.editedItem.replace ? elm.ret_id != null : elm.ret_id == null)
      );

      if (this.mvm_type == 1 || this.mvm_type == 3 || this.mvm_type == 23) {
        this.ret_exist =
          this.cmds_details.findIndex(
            (elm) =>
              elm.tier_id == this.editedItem.tier_id && elm.ret_id != null
          ) >= 0;
      }
      this.imports = [];
      if (this.mvm_type == 1 || this.mvm_type == 17) {
        this.imports = this.dossiers_list.filter(
          (elm) => elm.tier_id == this.editedItem.tier_id
        );
      }
      if (
        this.mvm_type == 1 ||
        this.mvm_type == 17 ||
        this.mvm_type == 3 ||
        this.mvm_type == 23 ||
        this.mvm_type == 19
      ) {
        if (this.cmds_details) {
          this.cmds = this.cmds_details.filter(
            (elm) => elm.tier_id == this.editedItem.tier_id
          );
        }
      }
      this.adresses = this.adress_list.filter(
        (elm) => elm.tier_id == this.editedItem.tier_id
      );
      this.cmpt++;
    },
    replace_change() {
      this.cmds_details_tier = this.cmds_details.filter(
        (elm) =>
          elm.tier_id == this.editedItem.tier_id &&
          (this.editedItem.replace ? elm.ret_id != null : elm.ret_id == null)
      );
    },
    get_tier_list() {
      if (
        this.mvm_type == 15 ||
        this.mvm_type == 16 ||
        this.mvm_type == 18 ||
        this.mvm_type == 21 ||
        this.mvm_type == 22
      ) {
        this.tiers_list = this.agents;
        if (this.tiers_list)
          if (this.tiers_list.length > 0)
            this.tiers_list.sort(this.sortBy("nom"));
      }
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    printMvm2(e) {
      this.editedItem.print_type = e;
      this.printMvm();
    },
    printMvm() {
      this.save_disable = true;
      if (
        this.mvm_type == 1 ||
        this.mvm_type == 2 ||
        this.mvm_type == 3 ||
        this.mvm_type == 23 ||
        this.mvm_type == 4 ||
        this.mvm_type == 6 ||
        this.mvm_type == 7 ||
        this.mvm_type == 8 ||
        this.mvm_type == 9 ||
        this.mvm_type == 14 ||
        this.mvm_type == 15 ||
        this.mvm_type == 16 ||
        this.mvm_type == 17 ||
        this.mvm_type == 18 ||
        this.mvm_type == 19 ||
        this.mvm_type == 21 ||
        this.mvm_type == 22
      ) {
        let doctype = [];
        doctype.push({
          lib: {
            content:
              this.mvm_type == 1
                ? "Bon de Reception"
                : this.mvm_type == 2
                ? "Bon de Retour Fournisseur"
                : this.mvm_type == 3 && this.editedItem.replace == 0
                ? "Bon d'Expedition"
                : this.mvm_type == 3 && this.editedItem.replace == 1
                ? "Bon de Restitution"
                : this.mvm_type == 4
                ? "Bon de Retour Client"
                : this.mvm_type == 6 || this.mvm_type == 9
                ? "Bon de Reception Interne"
                : this.mvm_type == 7
                ? "Bon de Sortie Matière"
                : this.mvm_type == 8
                ? "Bon de Réintégration"
                : this.mvm_type == 14
                ? "Bon de Reception NC"
                : this.mvm_type == 15 || this.mvm_type == 21
                ? "Bon de Sortie Magasin"
                : this.mvm_type == 16 || this.mvm_type == 22
                ? "Bon de Retour Magasin"
                : this.mvm_type == 17 || this.mvm_type == 23
                ? "ATTESTATION DE SERVICE FAIT"
                : this.mvm_type == 18
                ? "Bon d'Entrée Matière"
                : this.mvm_type == 19
                ? "Bon d'Entrée Produit"
                : "",
            styles: {
              fontStyle: "bold",
              fontSize: this.mvm_type == 17 || this.mvm_type == 23 ? 14 : 20,
              textColor: [0, 0, 0],
              cellPadding: { bottom: 3 },
            },
            colSpan: 2,
          },
        });
        doctype.push({
          lib: {
            content: "N° :",
            styles: { fontStyle: "normal", fontSize: 10 },
          },
          val: {
            content: this.editedItem.nobon,
            styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
          },
        });

        if (
          this.mvm_type == 1 ||
          this.mvm_type == 3 ||
          this.mvm_type == 23 ||
          this.mvm_type == 17 ||
          this.mvm_type == 19
        ) {
          doctype.push({
            lib: {
              content: "Date : ",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
            val: {
              content: this.editedItem.opr_date
                ? datefr(this.editedItem.opr_date)
                : "",
              styles: {
                fontStyle: "normal",
                fontSize: 10,
                textColor: [0, 0, 0],
              },
            },
          });
        }
        if (this.mvm_type == 7) {
          let ofs = "";
          this.editedItem.order.forEach((element) => {
            ofs = ofs + element.no_order + "/";
          });
          doctype.push({
            lib: {
              content: "OFs :",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
            val: {
              content: ofs,
              styles: {
                fontStyle: "normal",
                fontSize: 10,
                textColor: [0, 0, 0],
              },
            },
          });
        }
        let tier = [];
        tier.push({
          lib: {
            content:
              this.mvm_type == 1 || this.mvm_type == 2 || this.mvm_type == 17
                ? "Fournisseur :"
                : this.mvm_type == 3 ||
                  this.mvm_type == 23 ||
                  this.mvm_type == 4 ||
                  this.mvm_type == 19
                ? "Client"
                : this.rcpt_prod
                ? ""
                : "Demandé Par :",
            styles: { fontStyle: "normal", fontSize: 12, textColor: [0, 0, 0] },
            colSpan: 2,
          },
        });
        tier.push({
          lib: {
            content:
              (this.editedItem.forme_juridique
                ? this.editedItem.forme_juridique + " "
                : "") +
              (this.editedItem.tier_name ? this.editedItem.tier_name : ""),
            styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
            colSpan: 2,
          },
        });
        tier.push({
          lib: {
            content:
              this.mvm_type <= 4 || this.mvm_type == 17 || this.mvm_type == 19
                ? "Code :"
                : this.rcpt_prod
                ? ""
                : "Matricule",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.tier_code ? this.editedItem.tier_code : "",
            styles: {
              fontStyle: "normal",
              fontSize: 9,
              textColor: [0, 0, 0],
            },
          },
        });
        if (this.editedItem.tier) {
          tier.push({
            lib: {
              content: this.editedItem.tier.adresse
                ? this.editedItem.tier.adresse
                : "",
              styles: {
                fontStyle: "normal",
                fontSize: 9,
                textColor: [0, 0, 0],
              },
              colSpan: 2,
            },
          });
          tier.push({
            lib: {
              content: "Tél :",
              styles: { fontStyle: "normal", fontSize: 9 },
            },
            val: {
              content: this.editedItem.tier.tel ? this.editedItem.tier.tel : "",
              styles: {
                fontStyle: "normal",
                fontSize: 9,
                textColor: [0, 0, 0],
              },
            },
          });
          tier.push({
            lib: {
              content: "Email :",
              styles: { fontStyle: "normal", fontSize: 9 },
            },
            val: {
              content: this.editedItem.tier.email
                ? this.editedItem.tier.email
                : "",
              styles: {
                fontStyle: "normal",
                fontSize: 9,
                textColor: [0, 0, 0],
              },
            },
          });
        }
        if (this.mvm_type != 3) this.editedItem.print_type = "v";
        if (
          (this.mvm_type == 3 || this.mvm_type == 23) &&
          !(
            this.$store.state.auth.includes("01027") ||
            this.$store.state.isadmin
          )
        )
          this.editedItem.print_type = "q";
        let foot = [];
        if (
          (this.mvm_type <= 4 || this.mvm_type == 17 || this.mvm_type == 23) &&
          (this.$store.state.auth.includes("01027") ||
            this.$store.state.isadmin) &&
          this.editedItem.print_type == "v"
        ) {
          if (this.mvm_type != 3) {
            foot.push({
              libelle: "Montant HT",
              value: this.numberWithSpace(this.subTotal.toFixed(2)),
            });
            this.taxTotal.forEach((element) => {
              foot.push({
                libelle: "TVA " + element.taux + " %",
                value: this.numberWithSpace(element.tva.toFixed(2)),
              });
            });

            if (this.taxTotal.length > 1) {
              foot.push({
                libelle: "Total TVA",
                value: this.numberWithSpace(this.sumtaxTotal().toFixed(2)),
              });
            }
            if (
              this.subTotal > 0 &&
              this.sumtaxTotal() == 0 &&
              this.editedItem.country_id == 13
            ) {
              foot.push({
                libelle: "TVA ",
                value: "EXONORE",
              });
            }
          }
          foot.push({
            libelle: "Montant TTC",
            value: {
              content: this.numberWithSpace(this.grandTotal.toFixed(2)),
              styles: { fontStyle: "bold" },
            },
          });
        }
        let detailheaders = [
          {
            text: "No",
            value: "no",
            selected: true,
            align: "center",
            width: 10,
          },
          {
            text: "Code",
            value: "code",
            selected: true,
            align: "left",
            width: 20,
          },
          {
            text: "Compte",
            value: "compte",
            selected: true,
            align: "left",
            hiden: true,
            width: 20,
          },
          {
            text: "Désignation",
            value: "label",
            selected: true,
            align: "left",
            width: "auto",
          },
          {
            text: "Quantité",
            value: "qte",
            slot: "qte",
            width: 20,
            align: "end",
            selected: true,
          },
          {
            text: "Unité",
            value: "unit",
            width: 20,
            selected: true,
          },
        ];
        let lettre = [];
        let letter_h = [];
        if (this.mvm_type == 1) {
          if (this.detail_list) this.detail_list.sort(this.sortBy("compte"));
          detailheaders[2].hiden = false;
          let compte_list = this.detail_list.map((elm) => elm.compte);
          compte_list = compte_list.filter(onlyUnique);
          if (compte_list)
            compte_list.sort((a, b) => (a ? a.localeCompare(b) : ""));
          letter_h.push({ head: "Compte", datakey: "compte", halign: "left" });
          letter_h.push({ head: "Valeur", datakey: "value", halign: "right" });
          compte_list.forEach((element) => {
            let v = this.detail_list
              .filter((elm) => elm.compte == element)
              .reduce((a, b) => a + (b["ht"] || 0), 0);
            lettre.push({
              compte: {
                content: element,
                styles: { fontStyle: "normal", fontSize: 10 },
              },
              value: {
                content: this.numberWithSpace(v.toFixed(2)),
                styles: { fontStyle: "normal", fontSize: 10 },
              },
            });
          });
        }

        if (
          (this.mvm_type <= 4 || this.mvm_type == 17 || this.mvm_type == 23) &&
          (this.$store.state.auth.includes("01027") ||
            this.$store.state.isadmin) &&
          this.editedItem.print_type == "v"
        ) {
          let pu = this.mvm_type == 3 || this.mvm_type == 23 ? "pu_ttc" : "pu";
          let mont = this.mvm_type == 3 || this.mvm_type == 23 ? "total" : "ht";
          detailheaders.push({
            text: "PU ",
            value: pu,
            width: 15,
            align: "end",
            selected: true,
          });
          detailheaders.push({
            text: "Montant",
            value: mont,
            slot: "cur",
            width: 30,
            align: "end",
            selected: true,
          });
          // detailheaders.push({
          //   text: "Tx TVA",
          //   value: "tva_tx",
          //   slot: "cur",
          //   width: 20,
          //   align: "end",
          //   selected: true,
          // });
        }
        // detailheaders.push({
        //   text: "Observation",
        //   value: "comment",
        //   width: 70,
        //   selected: true,
        // });

        let t;
        if (this.mvm_type == 17) {
          t =
            "Nous Soussigné, Monsieur : " +
            (this.editedItem.preneur ? this.editedItem.preneur : "") +
            " agissant en qualité de : " +
            (this.editedItem.fonction ? this.editedItem.fonction : "") +
            " avons vérifié les articles ayant fait l'objet de commande ci-dessus selon le detail suivant : ";
          lettre.push({
            libelle: {
              content:
                "Et avons constaté que les travaux ont été réellement fidèlement exécutés ",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
          });
          lettre.push({
            libelle: {
              content: "En foi de quoi, nous signons cette attestation. ",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
          });
        }
        doc_template(
          this.$store,
          detailheaders,
          this.detail_list,
          doctype,
          tier,
          foot,
          lettre,
          this.editedItem.notes,
          this.mvm_type == 1
            ? "Bon de Reception"
            : this.mvm_type == 3
            ? "Bon d'expédition"
            : this.mvm_type == 6 || this.mvm_type == 9
            ? "Bon de Reception Interne"
            : this.mvm_type == 7
            ? "Bon de Sortie Matière"
            : this.mvm_type == 8
            ? "Bon de Réintégration"
            : this.mvm_type == 14
            ? "Bon de Reception NC"
            : this.mvm_type == 15 || this.mvm_type == 21
            ? "Bon de Sortie Magasin"
            : this.mvm_type == 16 || this.mvm_type == 22
            ? "Bon de Retour Magasin"
            : this.mvm_type == 17 || this.mvm_type == 23
            ? "ATTESTATION DE SERVICE FAIT"
            : this.mvm_type == 18
            ? "Bon d'Entrée Magasin"
            : this.mvm_type == 19
            ? "Bon d'Entrée Produit"
            : "",
          null,
          null,
          null,
          t,
          this.mvm_type == 17 ? "l" : "p",
          letter_h
        );
      }
      this.save_disable = false;
    },
    async deleteItem(item) {
      if (await this.verif_modif("m")) {
        let c;
        if (item.coliscount > 0) c = "Attention Liste de produit existe !!!!";
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer " +
              item.code +
              " " +
              item.label +
              "?",
            "",
            c
          )
        ) {
          this.loading_del = true;
          // if (item.coliscount > 0 && (this.rcpt_prod || this.mvm_stock)) {
          //   await this.maj(PRODUCT_LIST_DELETE, {
          //     mvm_detail_id: item.id,
          //   });
          // }

          let v = {
            id: item.id,
          };
          let r = await this.maj(DELETE_MVM_DETAIL, v);
          if (r) {
            if (this.req) this.detail_list.splice(item.index, 1);
            this.$store.dispatch("Changed", true);
            this.$emit("delete");
            this.update_modif();
            this.kdoc++;
          }
          this.$store.dispatch("Editing", false);
          this.loading_del = false;
        } else this.update_modif();
      }
    },
    ClosePayeForm() {
      this.showpaye = false;
    },
    get_motif(m) {
      this.editedItem.motif = m;
    },
    async solder_mvm() {
      this.is_motif = true;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir classer ce mouvement?",
          { color: "orange darken-3" }
        )
      ) {
        this.save_disable = true;
        let v = {
          mvm: [
            {
              statut_id: 12,
              motif: this.editedItem.motif,
            },
          ],
          mvm_id: this.mvm.id,
        };
        this.$store.dispatch("Editing", false);
        let r = await this.maj(MVM_UPDATE, v, true);
        if (r) {
          this.editedItem.statut_id = 12;
        }
        this.save_disable = false;
      } else this.$store.dispatch("Editing", false);
      this.is_motif = false;
    },
    async valider_mvm() {
      if (await this.verif_modif("m")) {
        this.save_disable = true;
        let c = "";
        this.valid = true;
        let ok = true;
        if (this.mvm_type <= 4 || this.mvm_type == 17 || this.mvm_type == 19)
          if (this.editedItem.tier.statut_id == 4) {
            ok = false;
            this.message = "erreur tier bloqué";
            this.type_alert = "error";
            this.alert = true;
          }
        //verifier qte
        if (ok) {
          let tot_qte = 0;
          for (let index = 0; index < this.detail_list.length; index++) {
            const element = this.detail_list[index];
            tot_qte = tot_qte + element.qte;
          }
          if (tot_qte == 0) {
            this.message = "Attention quantité totale null ";
            this.type_alert = "error";
            this.alert = true;
            ok = false;
          }
        }
        //verifier date mvm
        if (ok && ctl_mvmdate)
          for (let index = 0; index < this.detail_list.length; index++) {
            const element = this.detail_list[index];
            if (element.date_dern_mvm)
              if (this.editedItem.opr_date < element.date_dern_mvm) {
                this.message =
                  "Attention date opération inférieure à la date de dernier mouvement (" +
                  datefr(element.date_dern_mvm) +
                  ") pour l'article code " +
                  element.code +
                  " " +
                  element.label;
                this.type_alert = "error";
                this.alert = true;
                ok = false;
                break;
              }
          }
        //si dossier importation verifier articles
        if (
          ok &&
          this.mvm.statut_id == 10 &&
          (this.mvm_type == 1 || this.mvm_type == 17) &&
          this.mvm.country_id != 13 &&
          this.mvm.importdetails.length > 0
        ) {
          if (
            this.detail_list.map((elm) => elm.article_id).length !=
            this.mvm.importdetails.length
          ) {
            this.message =
              "Attention le nombre d'articles inférieur à celui de l'arrivage!- êtes-vous sûr?";
            this.type_alert = "warning";
            this.alert = true;
          }
          //verification stock
          else
            for (
              let index = 0;
              index < this.mvm.importdetails.length;
              index++
            ) {
              const element = this.mvm.importdetails[index];

              if (
                element.qte !=
                this.detail_list
                  .filter((elm) => elm.article_id == element.article_id)
                  .reduce(function (accumulator, item) {
                    return accumulator + parseFloat(item.qte);
                  }, 0)
              ) {
                this.message =
                  "Attention la quantité de l'article " +
                  element.label +
                  " inférieure à celui de l'arrivage!- êtes-vous sûr?";
                this.type_alert = "warning";
                this.alert = true;
                break;
              }
            }
        }
        //si reception production verifier stock

        if (
          ok &&
          this.mvm.statut_id == 10 &&
          (this.mvm_type == 6 || this.mvm_type == 9 || this.mvm_type == 14)
        ) {
          for (let index = 0; index < this.article_list.length; index++) {
            const element = this.article_list[index];
            let v = {
              mvm_detail_id: element.id,
            };
            let r = await this.requette(PRODUCTION_LIST_MVM, v);
            if (r) {
              let qte = r.production_list_mvm.reduce(
                (a, b) => a + (b["qte_rest"] || 0),
                0
              );

              if (Math.abs(qte - element.qte) > 1) {
                this.message =
                  "Stock production insuffisant pour le produit " +
                  (element.label ? element.label : element.product_name) +
                  " !!-verifier les quantités";
                this.type_alert = "error";
                this.alert = true;
                this.valid = false;
                ok = false;
                break;
              }
            }
          }
        }
        //si sortie/retour verifier stock
        if (
          ok &&
          this.mvm.statut_id == 10 &&
          (this.mvm_type == 3 ||
            this.mvm_type == 2 ||
            this.mvm_type == 4 ||
            this.mvm_type == 5 ||
            this.mvm_type == 7 ||
            this.mvm_type == 8 ||
            this.mvm_type == 15 ||
            this.mvm_type == 16 ||
            this.mvm_type == 21)
        ) {
          let article_list = [];
          this.article_list.forEach((element) => {
            if (element.links.length > 0) {
              element.links.forEach((elm) => {
                element.article_id = elm.link_id;
                article_list.push(element);
              });
            } else article_list.push(element);
          });
          if (this.mvm_type != 4) {
            let v = {
              article_list: article_list.map((elm) => elm.article_id),
              depot_list: this.depots,
              conforme: this.mvm_type == 14 ? 1 : null,
            };
            let depots_articles;
            let r = await this.requette(DEPOTS_ARTICLES, v);
            if (r) {
              depots_articles = r.depots_articles;
            }

            for (let index = 0; index < article_list.length; index++) {
              const element = article_list[index];
              let i = depots_articles.findIndex(
                (elm) =>
                  elm.article_id == element.article_id &&
                  elm.depot_id ==
                    (this.mvm_type == 5 ||
                    this.mvm_type == 7 ||
                    this.mvm_type == 8
                      ? element.mvm_from
                      : element.depot_id)
              );
              if (this.mvm_type != 8 && this.mvm_type != 16) {
                if (i == -1) ok = false;
                else ok = depots_articles[i].stock_cmpt >= element.qte;
                if (!ok) {
                  this.message =
                    "Stock depot " +
                    (this.mvm_type == 5
                      ? element.depot_from_name
                      : this.mvm_type == 7 || this.mvm_type == 8
                      ? element.depot_from
                      : element.depot_name) +
                    " " +
                    (i == -1 ? "0" : depots_articles[i].stock_cmpt) +
                    " insuffisant pour le produit " +
                    element.product_name +
                    " !!-verifier les quantités";
                  this.type_alert = "error";
                  this.alert = true;
                  this.valid = false;
                  break;
                }
              }
            }
          }
          let list_items = [];
          for (let index = 0; index < this.article_list.length; index++) {
            const element = this.article_list[index];
            //verifier si product liste existe encore
            if (element.coliscount > 0) {
              let v = {
                mvm_detail_id: element.id,
              };
              let r = await this.requette(PRODUCT_LIST_MVM, v);
              if (r) {
                let j = -1;
                let j2 = -1;
                //verifier si utilisation double de l'item

                for (
                  let index = 0;
                  index < r.product_list_mvm.length;
                  index++
                ) {
                  const elm5 = r.product_list_mvm[index];
                  j2 = list_items.findIndex((elm) => elm == elm5.id);
                  if (j2 >= 0) {
                    this.message =
                      "Utilisation double de l'item N° " +
                      elm5.code +
                      " du Produit " +
                      element.product_name +
                      " !!-verifier";
                    this.type_alert = "error";
                    this.alert = true;
                    this.valid = false;
                    ok = false;
                    break;
                  } else {
                    list_items.push(elm5.id);
                  }
                }
                if (!ok) {
                  break;
                }

                if (
                  this.mvm_type == 4 ||
                  this.mvm_type == 16 ||
                  this.mvm_type == 22
                )
                  j = r.product_list_mvm.findIndex((elm) => elm.qte_rest > 0);
                else if (
                  this.mvm_type == 5 ||
                  this.mvm_type == 7 ||
                  this.mvm_type == 8
                ) {
                  j = r.product_list_mvm.findIndex(
                    (elm) => elm.depot_id != element.mvm_from
                  );
                } else
                  j = r.product_list_mvm.findIndex((elm) => elm.qte_rest == 0);
                if (j >= 0) {
                  this.message =
                    "Item N° " +
                    r.product_list_mvm[j].code +
                    " du Produit " +
                    element.product_name +
                    " n'est pas disponible !!-verifier";
                  this.type_alert = "error";
                  this.alert = true;
                  this.valid = false;
                  ok = false;
                  break;
                }
              }
            }
          }
        }
        //verifier avance des commandes
        if (
          ok &&
          this.mvm.statut_id == 10 &&
          (this.mvm.mvm_type == 3 || this.mvm.mvm_type == 23)
        ) {
          ok = true;
          for (let index = 0; index < this.detail_list.length; index++) {
            let element = this.detail_list[index];
            if (!element.avance) {
              this.message =
                "Attention l'article code " +
                element.code +
                " " +
                element.label +
                " ne peux pas être expedier pour probleme avance !!-verifier";
              this.type_alert = "error";
              this.alert = true;
              ok = false;
              break;
            }
          }
        }
        //verifier credit
        if (
          this.mvm.statut_id == 10 &&
          ok &&
          (this.mvm.mvm_type == 3 || this.mvm.mvm_type == 23)
        ) {
          let dd = new Date(this.$store.state.exercice + "-01-01")
            .toISOString()
            .slice(0, 10);
          let df = this.$store.state.today;
          if (
            this.mvm.statut_id == 10 &&
            (this.mvm.mvm_type == 3 || this.mvm.mvm_type == 23) &&
            this.mvm.credit_acc > 0
          ) {
            let v = {
              tier_id: this.mvm.tier_id,
              date_debut: dd,
              date_fin: df,
            };
            let r = await this.requette(SIT_CLIENT, v);
            let sit_client = {};
            if (r) sit_client = r.sit_client[0];
            if (
              this.mvm.credit_acc <
              this.grandTotal + sit_client.situation_gl
            ) {
              c = "Attention depassement seuil credit accordé !!";
            }
          }
        }

        this.$store.dispatch("Editing", false);
        if (ok) {
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Etês-vous sûr de vouloir valider ce mouvement?",
              c.length > 0 ? { color: "orange darken-3" } : "",
              c
            )
          ) {
            let st = 11;

            if (
              (this.mvm_type < 5 || this.mvm_type == 17) &&
              this.subTotal == 0
            )
              st = 12;
            else if (
              (this.mvm_type == 1 && this.editedItem.country_id != 13) ||
              this.mvm_type == 5 ||
              this.mvm_type == 7 ||
              this.mvm_type == 8 ||
              this.mvm_type == 15 ||
              this.mvm_type == 16 ||
              this.mvm_type == 18 ||
              this.mvm_type == 19 ||
              this.mvm_type == 21 ||
              this.mvm_type == 22
            ) {
              st = 27;
            } else if (this.rcpt_prod) st = 26;

            let v = {
              mvm: [
                {
                  statut_id: st,
                  nobon: this.editedItem.nobon,
                },
              ],
              orders: this.orders.length > 0 ? this.orders : null,
              mvm_id: this.mvm.id,
              sens: 1,
            };
            this.$store.dispatch("Editing", false);
            let r = await this.maj(MVM_UPDATE, v, true);
            if (r) {
              this.editedItem.statut_id = st;
              this.$emit("refresh");
            }
            // if (this.mvm_type == 1 && this.editedItem.modified > 1)
            //   this.regul_stock();
          }
        }
        this.update_modif();
        this.$store.dispatch("Editing", false);
        this.is_motif = false;
        this.kdoc++;
        this.valid = false;
      }
      this.save_disable = false;
    },

    nbjour() {
      if (this.editedItem.mvm_date && this.duedate) {
        var date2 = new Date(this.duedate);
        var date1 = new Date(this.editedItem.mvm_date);
        var diff = date2.getTime() - date1.getTime();
        this.editedItem.delai_paye = Math.floor(diff / (24 * 3600 * 1000));
        this.cmpt++;
      } else this.editedItem.delai_paye = null;
    },
    addDays() {
      if (this.editedItem.delai_paye) {
        let d = parseInt(this.editedItem.delai_paye);
        var result = new Date(this.editedItem.mvm_date);
        result.setDate(result.getDate() + d);
        this.duedate = result.toISOString().substr(0, 10);
        this.kdd++;
      } else this.duedate = null;
      this.cmpt++;
    },
    datefr(date, time = false, long = true) {
      let t = "";
      let option = {
        year: "numeric",
        month: long ? "long" : "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async OpenMvmForm(item) {
      if (item.col == "code") {
        if (this.mvm_type == 3 || this.mvm_type == 4)
          await this.$router
            .push({
              name: "product_tosell",
              params: {
                mvm: this.mvm,
                item: item,
                name: this.mvm_type == 3 ? "livraisons" : "retours_c",
              },
            })
            .catch(() => {});
        if (this.mvm_type == 1 || this.mvm_type == 2)
          await this.$router
            .push({
              name: "product_tobuy",
              params: {
                mvm: this.mvm,
                item: item,
                name: this.mvm_type == 1 ? "receptions_e" : "retours_f",
              },
            })
            .catch(() => {});
      } else if (await this.verif_modif(item.col == "pu" ? "p" : "m")) {
        this.mvmdetail = item;
        this.mvmdetail.mvm_id = this.editedItem.id;
        this.mvmdetail.tier_id = this.editedItem.tier_id;
        this.mvmdetail.tol_qte = this.mvm.cmd
          ? this.mvm.cmd.tol_qte
            ? this.mvm.cmd.tol_qte
            : this.$store.state.options[0].tol_qte_cmd_plus
          : this.$store.state.options[0].tol_qte_cmd_plus;
        if (
          this.mvm_type == 1 ||
          this.mvm_type == 17 ||
          this.mvm_type == 18 ||
          this.mvm_type == 19
        ) {
          this.isRcptClosed = false;
          this.rkey++;
        }
        if (this.mvm_type == 2 || this.mvm_type == 4) {
          this.isRetExtClosed = false;
          this.retextkey++;
        }
        if (this.mvm_type == 16 || this.mvm_type == 22) {
          this.isRetIntClosed = false;
          this.retintkey++;
        }
        if (
          this.mvm_type == 3 ||
          this.mvm_type == 23 ||
          this.mvm_type == 7 ||
          this.mvm_type == 15 ||
          this.mvm_type == 21
        ) {
          this.isLivClosed = false;
          this.lkey++;
        } else if (this.mvm_type == 5 || this.mvm_type == 8) {
          if (!this.editedItem.dem_id) {
            this.isTransfertClosed = false;
            this.tkey++;
          } else {
            this.isTransfertDemClosed = false;
            this.tkeyf++;
          }
        } else if (this.rcpt_prod) {
          this.isProdClosed = false;
          this.pkey++;
        }
      }
    },
    async unlock() {
      this.save_disable = true;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir déverrouiller ce " + this.title + "?"
        )
      ) {
        this.editedItem.statut_id = 10;

        let v = {
          mvm: [
            {
              statut_id: this.editedItem.statut_id,
              modified: this.editedItem.modified + 1,
            },
          ],
          mvm_id: this.mvm.id,
          sens: -1,
        };
        await this.maj(MVM_UPDATE, v, true);
        this.$store.dispatch("Editing", false);
        this.$emit("refresh");
      }
      this.save_disable = false;
    },
    OpenDocList() {
      this.isDocListClose = false;
      this.fdocl++;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.progress = false;
        });
      return r;
    },
    async regul_stock() {
      let articles_list = [];
      let articles = [];
      let articles_mvms = [];
      let mvms_detail = [];
      let mvms = [];
      let q_stock = [];
      // this.loading_data = true;

      articles_list = this.detail_list;
      let v = {
        article_id: articles_list.map((elm) => elm.article_id),
        desc: "ASC",
      };
      let r = await this.requette(MVMS, v);
      this.loading_data = false;
      if (r) {
        articles_mvms = r.product_mvms;
        let depots = articles_mvms
          .map((elm) => elm.depot_id)
          .filter(onlyUnique);
        let st_neg = [];
        let mv_neg = [];
        for (let i = 0; i < articles_list.length; i++) {
          const article = articles_list[i];
          let article_mvms = articles_mvms.filter(
            (elm) => elm.sens != 0 && elm.article_id == article.id
          );

          for (let index = 0; index < article_mvms.length; index++) {
            const mvm = article_mvms[index];
            let j = -1;
            let qte_actual = 0;
            let u = articles.findIndex((elm) => elm.id == article.id);
            if (u >= 0) {
              articles[u].date_dern_mvm = mvm.mvm_date;
              article.pmp = articles[u].pmp;
            } else
              articles.push({
                id: article.id,
                pmp: 1,
                date_dern_mvm: mvm.mvm_date,
              });
            //si inventaire
            if (mvm.mvm_type == 13) {
              q_stock.push({
                article_id: article.id,
                depot_id: mvm.depot_id,
                stock_cmpt: mvm.qte_mvm,
              });
              article.pmp = mvm.pu ? mvm.pu : 1;
              u = articles.findIndex((elm) => elm.id == article.id);
              if (u >= 0) articles[u].pmp = article.pmp;
            }
            //si reception
            else if (mvm.mvm_type == 1) {
              j = q_stock.findIndex(
                (elm) =>
                  elm.article_id == article.id && elm.depot_id == mvm.depot_id
              );
              if (j >= 0) {
                qte_actual = q_stock[j].stock_cmpt;
                q_stock[j].stock_cmpt = qte_actual + mvm.qte_mvm;
              }
              if (j == -1)
                q_stock.push({
                  article_id: article.id,
                  depot_id: mvm.depot_id,
                  stock_cmpt: mvm.qte_mvm,
                });
              article.pmp =
                (article.pmp * qte_actual + mvm.pu * mvm.qte_mvm) /
                (qte_actual + mvm.qte_mvm);
              article.pmp =
                Math.round((article.pmp + Number.EPSILON) * 100000) / 100000;
              u = articles.findIndex((elm) => elm.id == article.id);
              if (u >= 0) articles[u].pmp = article.pmp;
            } else {
              //sortie
              if (mvm.sens == -1 && mvm.destock == "0") {
                let st = 0;
                j = q_stock.findIndex(
                  (elm) =>
                    elm.article_id == article.id && elm.depot_id == mvm.depot_id
                );

                if (j >= 0) {
                  st = q_stock[j].stock_cmpt - mvm.qte_mvm;
                  q_stock[j].stock_cmpt = st;
                } else {
                  st = -mvm.qte_mvm;
                  q_stock.push({
                    article_id: article.id,
                    depot_id: mvm.depot_id,
                    stock_cmpt: st,
                  });
                }
                if (st < 0)
                  mv_neg.push({
                    article_id: article.id,
                    error: "Mouvement negatif",
                    mvm_id: mvm.mvm_id,
                    nobon: mvm.nobon,
                    mvm_detail_id: mvm.id,
                  });
              }
              //entree
              if (mvm.sens == 1) {
                j = q_stock.findIndex(
                  (elm) =>
                    elm.article_id == article.id && elm.depot_id == mvm.depot_id
                );
                if (j >= 0)
                  q_stock[j].stock_cmpt = q_stock[j].stock_cmpt + mvm.qte_mvm;
                else {
                  q_stock.push({
                    article_id: article.id,
                    depot_id: mvm.depot_id,
                    stock_cmpt: mvm.qte_mvm,
                  });
                }
              }
              if (mvm.pu != article.pmp) {
                //maj pu mvm
                mvms_detail.push({
                  id: mvm.id,
                  article_id: article.id,
                  pu: article.pmp,
                });
                let h = mvms.findIndex((elm) => elm == mvm.mvm_id);
                if (h == -1) mvms.push(mvm.mvm_id);
              }
            }
          }
          for (let index = 0; index < depots.length; index++) {
            const depot = depots[index];
            let n = q_stock.findIndex(
              (elm) =>
                elm.article_id == article.id &&
                elm.depot_id == depot &&
                elm.stock_cmpt < 0
            );
            if (n >= 0)
              st_neg.push({
                article_id: article.id,
                error: "Stock negatif",
                depot_id: depot,
                stock_cmpt: q_stock[n].stock_cmpt,
              });
          }
          this.taux_trait = ((i / articles_list.length) * 100).toFixed(2);
          this.kbt++;
        }

        //maj stock
        if (articles.length > 0 || mvms_detail > 0 || q_stock > 0)
          this.loading_data = true;
        v = {
          articles: articles,
          mvms_detail: mvms_detail,
          depots: q_stock,
          mvms: mvms,
        };

        await this.maj(UPDATE_ARTICLES, v);
        this.loading_data = false;
        if (st_neg.length > 0 || mv_neg.length > 0) {
          this.snackbar_text =
            "Attention des erreurs de stock/Mouvement negatif detectés consulter la console";
          this.snackbar_color = "error";
          this.snackbar = true;
          if (st_neg.length > 0) {
            console.log("Stock Negatif");
            console.log(st_neg);
          }
          if (mv_neg.length > 0) {
            console.log("Mouvement Negatif");
            console.log(mv_neg);
          }
        } else {
          this.snackbar_text = "Stock régularisé avec succes";
          this.snackbar_color = "success";
          this.snackbar = true;
        }
      }
    },

    OpenCmdForm() {
      this.$emit("open_cmd", this.cmd);
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },

    numberWithSpace: function (x) {
      return x
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "";
    },

    decimalDigits: function (value) {
      return typeof value == "number" ? value.toFixed(2) : 0;
    },
    printCmd() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("invoice-app2").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=200,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    adjustTextAreaHeight: function (event) {
      var el = event.target;
      el.style.height = "1px";
      el.style.height = 25 + el.scrollHeight + "px";
    },
    sumtaxTotal() {
      // sum data in give key (property)
      return this.taxTotal
        ? this.taxTotal.reduce(
            (a, b) => a + (parseFloat(((b.ht * b.taux) / 100).toFixed(2)) || 0),
            0
          )
        : 0;
    },
    async save() {
      if (this.$refs.form ? this.$refs.form.validate() : true) {
        this.save_disable = true;
        if (this.mvm_type == 5 || this.mvm_type == 7 || this.mvm_type == 8) {
          this.editedItem.tier_id = "-1";
        }
        let modif = this.editedItem.id > 0;
        let v = {
          mvm: [
            {
              mvm_type: this.mvm_type,
              mvm_ref: this.editedItem.mvm_ref,
              //   cmd_id: this.editedItem.cmd_id,
              liv_id: this.editedItem.liv_id,
              dossier_id: this.editedItem.dossier_id,
              tier_id: this.editedItem.tier_id,
              dem_id: this.editedItem.dem_id,
              depot_from: this.editedItem.depot_from,
              mvm_date: this.editedItem.mvm_date,
              adress_id: this.editedItem.adress_id,
              comment: this.editedItem.comment,
              statut_id: this.editedItem.statut_id,
              delai_paye: this.editedItem.delai_paye,

              agent_id: this.editedItem.agent_id,
              opr_date: this.editedItem.opr_date,
              areg: this.editedItem.areg ? 1 : 0,
              decision_ret: this.editedItem.decision_ret,
              motif_ret: this.editedItem.motif_ret,
              replace: this.editedItem.replace ? 1 : 0,
            },
          ],
          mvm_id: this.editedItem.id > 0 ? this.mvm.id : null,
          mvm_type: this.mvm_type,
          orders: this.orders.length > 0 ? this.orders : null,
          rcpt:
            this.editedItem.id < 0 &&
            this.mvm_type == 1 &&
            this.$route.params.cmd
              ? [
                  {
                    cmd_id: this.$route.params.cmd.id,
                    depot_id: this.editedItem.depot_id,
                  },
                ]
              : null,
        };

        let r = await this.maj(MVM_UPDATE, v, true);
        if (r) {
          if (this.editedItem.id < 0) this.editedItem.id = r.UpdateMvm;
          this.$store.dispatch("Editing", false);

          this.$emit(modif ? "update" : "change", this.editedItem);
          if (this.$route.params.cmd) this.$route.params.cmd = null;
          this.$store.dispatch("Changed", true);

          //this.editedItem.id = r.createMvm.id;

          this.edit = false;
          this.$emit("edition", false);
          this.kdoc++;
        }
        this.save_disable = false;
      }
    },
  },
  computed: {
    depots_from() {
      return this.$store.state.depots.filter(
        (elm) =>
          elm.ChildCount == 0 && elm.type_id == (this.mvm_type == 5 ? 2 : 3)
      );
    },
    retourcomex() {
      return retourcomex;
    },
    whereadd() {
      return [{ column: "COUNTRY_ID", value: 13 }];
    },
    variableadd() {
      let w = {
        TypeScope:
          this.mvm_type == 1 || this.mvm_type == 2 || this.mvm_type == 17
            ? 1
            : 2,
        statut_id: [2, 3],
        Kind: 0,
      };
      return w;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },
    can_unlock() {
      let ok = true;
      for (let index = 0; index < this.detail_list.length; index++) {
        const element = this.detail_list[index];
        if (element.date_dern_mvm)
          if (this.editedItem.opr_date < element.date_dern_mvm) {
            ok = false;
            break;
          }
      }
      if (unlock_mvm && this.$store.state.isadmin) ok = true;
      return ok;
    },
    auth() {
      let a =
        this.mvm_type == 1 || this.mvm_type == 17
          ? "01007"
          : this.mvm_type == 2
          ? "01008"
          : this.mvm_type == 3
          ? "01009"
          : this.mvm_type == 23
          ? "01100"
          : this.mvm_type == 4
          ? "01010"
          : this.mvm_type == 6
          ? "01024"
          : this.mvm_type == 9
          ? "01044"
          : this.mvm_type == 14
          ? "01062"
          : "";
      return a;
    },
    rcpt_prod() {
      return this.mvm_type == 6 || this.mvm_type == 9 || this.mvm_type == 14;
    },
    mvm_stock() {
      return (
        this.mvm_type == 7 ||
        this.mvm_type == 8 ||
        this.mvm_type == 5 ||
        this.mvm_type == 15 ||
        this.mvm_type == 16 ||
        this.mvm_type == 18 ||
        this.mvm_type == 19 ||
        this.mvm_type == 21 ||
        this.mvm_type == 22
      );
    },
    depots() {
      return this.$store.state.depots
        .filter((elm) => elm.ChildCount == 0)
        .map((elm) => elm.id);
    },
    departement() {
      let t = "";
      if (this.editedItem.departement_name)
        t = this.editedItem.departement_name;
      if (this.editedItem.ville_name) t = t + "-" + this.editedItem.ville_name;

      return t;
    },
    title() {
      let t;
      let i = this.mvms_type.findIndex((elm) => elm.id == this.mvm_type);
      if (i >= 0) t = this.mvms_type[i].mouvment;
      return t;
    },
    paye_list: function () {
      return this.mvm.id > 0 ? this.mvm.paiements : [];
    },
    existe_remise() {
      let i = this.detail_list.findIndex((elm) => elm.remise > 0);
      return i >= 0;
    },
    importdetails: function () {
      let l = [];
      if (this.mvm) {
        if (this.mvm.importdetails)
          l = this.mvm.importdetails.filter((elm) => elm.qte_rest > 0);
      }
      return this.mvm.id > 0 ? l : [];
    },

    // cmd_details: function () {
    //   let l = [];
    //   if (this.mvm.cmd) {
    //     l = this.mvm.cmddetails;
    //   }
    //   return this.mvm.id > 0 ? l : [];
    // },
    tier_articles: function () {
      let l = [];
      if (this.mvm.articles) {
        l = this.mvm.articles;
      }
      return this.mvm.id > 0 ? l : [];
    },
    retours: function () {
      let l = [];
      if (this.mvm.retours) {
        l = this.mvm.retours;
      }
      return this.mvm.id > 0 ? l : [];
    },
    client_products: function () {
      let l = [];
      if (this.mvm.client_products) {
        l = this.mvm.client_products;
      }
      return this.mvm.id > 0 ? l : [];
    },
    article_list: function () {
      let l = [];
      this.detail_list
        .filter((elm) => elm.service == "0")
        .forEach((element) => {
          l.push({
            id: element.id,
            depot_id: element.depot_id,
            mvm_from: element.mvm_from,
            depot_name: element.depot_name,
            depot_from: element.depot_from,
            product_name: element.product_name,
            article_id: element.article_id,
            qte: element.qte,
            coliscount: element.coliscount,
            links: element.links,
          });
        });
      return l;
    },

    tier_title() {
      let t;
      if (this.mvm_type == 1 || this.mvm_type == 17 || this.mvm_type == 2)
        t = "Fournisseur";
      else if (
        this.mvm_type == 3 ||
        this.mvm_type == 23 ||
        this.mvm_type == 4 ||
        this.mvm_type == 19
      )
        t = "Client";
      else if (this.rcpt_prod) t = "Production";
      else if (this.mvm_type == 5 || this.mvm_type >= 9) t = "Demandé Par";
      return t;
    },
    cmd_name() {
      let c = "";
      if (this.cmds_list) {
        let i = this.cmds_list.findIndex((elm) => elm.id == this.mvm.cmd_id);
        if (i >= 0)
          c = this.cmds_list[i].label
            ? this.cmds_list[i].label
            : this.cmds_list[i].tier_name;
      }
      return c;
    },
    formTitle() {
      let t;
      let i = this.mvms_type.findIndex((elm) => elm.id == this.mvm_type);
      if (i >= 0) {
        t = this.mvms_type[i].mouvment;
      }
      if (this.editedItem.replace) t = "Bon de restitution";
      return this.editedItem.id < 0 || !this.editedItem.nobon
        ? t
        : t + " N° " + this.editedItem.nobon;
    },
    garantie() {
      return grt ? true : false;
    },
    logo() {
      return myLogo;
    },
    myname() {
      return myName;
    },

    mode() {
      let i = this.modes.findIndex(
        (elm) => elm.id == this.editedItem.modepaiement
      );
      let modepaiement = "";
      if (i >= 0) modepaiement = this.modes[i].mode;
      return modepaiement;
    },

    tier_label() {
      let t;
      if (
        this.mvm_type == 3 ||
        this.mvm_type == 23 ||
        this.mvm_type == 4 ||
        this.mvm_type == 19
      )
        t = "Client";
      if (this.mvm_type == 1 || this.mvm_type == 17 || this.mvm_type == 2)
        t = "Fournisseur";
      if (
        this.mvm_type == 5 ||
        this.mvm_type == 15 ||
        this.mvm_type == 16 ||
        this.mvm_type == 18 ||
        this.mvm_type == 21 ||
        this.mvm_type == 22
      )
        t = "Demandé Par";
      return t;
    },

    myrc() {
      return myRC;
    },
    myif() {
      return myIF;
    },
    myart() {
      return myART;
    },

    operations: function () {
      return this.editedItem.id > 0 ? this.editedItem.operations : [];
    },

    detail_list: function () {
      let l = [];
      if (this.editedItem)
        if (this.editedItem.mvmdetails) l = this.editedItem.mvmdetails;
      return l;
    },
    mvmlivs: function () {
      return this.mvm.mvmlivs ? this.mvm.mvmlivs : [];
    },
    subTotal: function () {
      var total = this.detail_list
        ? this.detail_list.reduce(function (accumulator, item) {
            return (
              accumulator +
              parseFloat(
                ((item.pu - (item.pu * item.remise) / 100) * item.qte).toFixed(
                  2
                )
              )
            );
          }, 0)
        : 0;
      return total;
    },
    remise: function () {
      var total = this.detail_list
        ? this.detail_list.reduce(function (accumulator, item) {
            return (
              accumulator +
              parseFloat(
                (((item.pu * item.remise) / 100) * item.qte).toFixed(2)
              )
            );
          }, 0)
        : 0;
      return total;
    },
    taxTotal: function () {
      let taxs = [];
      if (this.detail_list) {
        this.detail_list.forEach((element) => {
          if (
            element.tva_tx &&
            (element.exe_tva == "0" || element.exe_tva == null)
          ) {
            let ht = (
              (element.pu - (element.pu * element.remise) / 100) *
              element.qte
            ).toFixed(2);

            let i = taxs.findIndex((elm) => elm.taux == element.tva_tx);
            if (i >= 0) {
              taxs[i].ht = parseFloat(taxs[i].ht) + parseFloat(ht);
              taxs[i].tva =
                parseFloat(taxs[i].tva) +
                parseFloat(ht * (element.tva_tx / 100));
            } else
              taxs.push({
                ht: parseFloat(ht),
                taux: element.tva_tx,
                tva: ht * (element.tva_tx / 100),
                exe_tva: element.exe_tva,
              });
          }
        });
      }
      return taxs;
    },
    grandTotal: function () {
      let taxs = 0;
      this.taxTotal.forEach((element) => {
        taxs =
          taxs + parseFloat((element.ht * (element.taux / 100)).toFixed(2));
      });
      var total = parseFloat(this.subTotal) + taxs;
      return total;
    },
  },
};
</script>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.text-green input {
  color: green !important;
}
.text-input-blue .v-text-field__slot input {
  color: #00f !important;
}
</style>
