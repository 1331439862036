import jsPDF from "jspdf";
import "jspdf-autotable";
import { addHeaders,addFootersFactList, marges2 } from "print/entete.js";
import { numberWithSpace,datefr} from "@/utils/functions.js";
import { entites } from "print/data.js";

  export function liste (store,data_item,mvm_det,colis_list,attributs_list) {
 let   colisheaders= [
      {
        text: "No",
        value: "no",
        selected: true,       
        width: 10,
        align: "center",
      },
      {
        text: "Code",
        value: "ref",
        selected: true,       
        width: 50,
        align: "left",
      },
      {
        text: "Quantite",
        value: "qte",
        total: "qte",
        slot: "cur3",
        align: "right",
        selected: true,        
        width: "auto",
      },
    ]
    //colisheaders[1].value= store.state.options[0].code_produit == "1" ? "ref": "code"
    colisheaders[2].text= mvm_det.unit_name ? mvm_det.unit_name :"Quantite"
var doc = new jsPDF();
  let marges = marges2(doc,store);


    
  let totpages = 0;
  let pageStart = 1;
let onepx = (doc.internal.pageSize.width - marges.margin_left - marges.margin_right) / 100;

 
    let headers = [];

    colisheaders.forEach((element) => {
      headers.push(element);
    });
    let colis_attributs = [];
    let  entite_name = "";
   // let  entite_ville = "";
    
    let f=entites.findIndex(elm=>elm.id==1);
    if (f>=0){
      entite_name =entites[f].name;
   //   entite_ville =entites[f].ville_name;
    }

    
      headers[2].text = mvm_det.unit_name 
      colis_attributs = mvm_det.colisattributs;
    

    for (let index = 0; index < colis_attributs.length; index++) {
      const element = colis_attributs[index];
      let i = attributs_list.findIndex((elm) => elm.id == element);
      if (i >= 0) {
        let ls = [];
        attributs_list[i].attributvalues.forEach((element) => {
          ls.push(element.name);
        });
        let elm = {
          text: attributs_list[i].caption
            ? attributs_list[i].caption
            : attributs_list[i].name,
          value: "ref" + index,
          width: attributs_list[i].width
            ? attributs_list[i].width * onepx
            : null,
          align: attributs_list[i].align,
          selected: true,
        };
        headers.splice(index + 2, 0, elm);
      }
    }

    let head = [];
    let columns = [];
    let items = [];
    let item;

    totpages = doc.internal.getNumberOfPages();
    let list_prod=colis_list
    for (let index = 0; index < list_prod.length; index++) {
      item = {};
      headers.forEach((elm) => {
        if (elm.value == "no") {
          item[elm.value] = index + 1;
        }
        if (elm.slot == "cur22") {
          item[elm.value] = numberWithSpace(
            parseFloat(list_prod[index][elm.value]).toFixed(2)
          );
        } else if (elm.slot == "cur" && list_prod[index][elm.value]) {
          item[elm.value] = numberWithSpace(
            parseFloat(list_prod[index][elm.value]).toFixed(2)
          );
        } else if (elm.slot == "cur3" && list_prod[index][elm.value]) {
          item[elm.value] = numberWithSpace(
            parseFloat(list_prod[index][elm.value]).toFixed(3)
          );
        } else if (list_prod[index][elm.value])
          item[elm.value] = list_prod[index][elm.value];
          if (item[elm.value] && typeof item[elm.value]=='string')
            item[elm.value] = item[elm.value].replace(/μ/g, "\u00B5");   
      });
    
      items.push(item);
    }

    let columnStyles = {};

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(
      data_item.mvm_type == 1 || data_item.mvm_type == 2
        ? "Fournisseur : "
        : data_item.mvm_type == 3 || data_item.mvm_type == 4
        ? "Client : " :  data_item.mvm_type == 6 || data_item.mvm_type == 9 || data_item.mvm_type == 14 ? "" : "Monsieur",
      marges.margin_left,
      marges.margin_top + marges.l_h + 25
    );
    doc.text(data_item.tier_name ?  data_item.tier_name : "", marges.margin_left, marges.margin_top + marges.l_h + 30);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    if (data_item.mvm_type == 1 || data_item.mvm_type == 3 || data_item.mvm_type == 17) {
    doc.text("Commande N°: ", marges.margin_left, marges.margin_top + marges.l_h + 35);
    doc.text(
      data_item.nocmd ? data_item.nocmd : "",
      marges.margin_left + 35,
      marges.margin_top + marges.l_h + 35
    );
    doc.text("Date: ", marges.margin_left + 60, marges.margin_top + marges.l_h + 35);
    doc.text(datefr(data_item.date_cmd), marges.margin_left + 70, marges.margin_top + marges.l_h + 35);
    }
    let mvm_type= data_item.mvm_type
  let title = mvm_type == 1
    ? "Bon de Reception"
    : mvm_type == 2
    ? "Bon de Retour Fournisseur"
    : mvm_type == 3
    ? "Bon d'expédition"
    : mvm_type == 4
    ? "Bon de Retour Client"
    : mvm_type == 5
    ? "Bon de Transfert"
    : mvm_type == 6 || mvm_type == 9
    ? "Bon de Reception Interne"
    : mvm_type == 7
    ? "Bon de Sortie Matière"
    : mvm_type == 8
    ? "Bon de Réintégration"
    : mvm_type == 14
    ? "Bon de Reception NC"
    : mvm_type == 15 || mvm_type == 21
    ? "Bon de Sortie Magasin"
    : mvm_type == 16 || mvm_type == 22
    ? "Bon de Retour Magasin"
    : mvm_type == 17
    ? "ATTESTATION DE SERVICE FAIT"
    : ""
    doc.text(title, marges.margin_left, marges.margin_top + marges.l_h + 39);
    doc.text("Date: ", marges.margin_left + 65, marges.margin_top + marges.l_h + 39);
    doc.text(datefr(data_item.mvm_date), marges.margin_left + 75, marges.margin_top + marges.l_h + 39);
    doc.text("Produit     : ", marges.margin_left, marges.margin_top + marges.l_h + 43);
    doc.setFont("helvetica", "bold");
  
    doc.text(
      data_item.nomvm ? data_item.nomvm : data_item.nobon,
      marges.margin_left + 45,
      marges.margin_top + marges.l_h + 39
    );
    doc.text(
      mvm_det.product_name ? mvm_det.product_name : "",
      marges.margin_left +17,
      marges.margin_top + marges.l_h + 43
    );

    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text("Liste de Colisage", marges.margin_left + 70, marges.margin_top + marges.l_h + 20);

    item = {};
    headers.forEach((element) => {
      head.push({
        content: element.text,
        styles: {
          halign: element.align,
        },
      });

      columns.push({ dataKey: element.value });
      columnStyles[element.value] = {
        cellWidth: element.width,
        valign: "middle",
        halign: element.align,
      };
    });

    doc.autoTable({
      margin: { top: marges.margin_top + marges.l_h + 45, bottom: marges.margin_bottom + marges.p_h },
      didDrawPage: function(data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = marges.margin_top + marges.l_h + 10;
      },
      headStyles: {
        halign: "center",
        valign: "middle",
        fillColor: [128, 128, 128],
      },
      columnStyles: columnStyles,
      head: [head],
      body: items,
      columns: columns,
      showHead: "everyPage",
      showFoot: "everyPage",
      theme: "striped",
    });

    let total = list_prod.reduce((a, b) => a + (b["qte"] || 0), 0);
    let foot = [];
    foot.push({
      libelle: "Total en " + mvm_det.unit_name,
      value: numberWithSpace(total.toFixed(3)),
    });

    columns = [];
    columns.push({ dataKey: "libelle" });
    columns.push({ dataKey: "value" });
    doc.autoTable({
      margin: { left: marges.margin_left },
      body: foot,
      columnStyles: {
        0: { halign: "left", cellWidth: 60 },
        1: {
          halign: "right",
          cellWidth: "auto",
          fontStyle: "bold",
          fontSize: 12,
        },
      },
      columns: columns,
      theme: "plain",
      styles: {
        valign: "middle",
        fontSize: 12,
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 0,
      },
      tableWidth: "auto",
    });
    if (mvm_type==3) {
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 15,
      head: [["Pour " + entite_name, "Pour " + data_item.tier_name]],
      columnStyles: {
        0: {
          cellWidth:
            (doc.internal.pageSize.width - marges.margin_left - marges.margin_right) / 2,
        },
        1: { cellWidth: "auto" },
      },
      theme: "plain",
      headStyles: {
        halign: "left",
        valign: "middle",
      },
      tableWidth: doc.internal.pageSize.width - marges.margin_left - marges.margin_right,
    });
  }
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    addFootersFactList(doc,store, pageStart, totpages);

  addHeaders(doc, store,null,null,entite_name);

  doc.setProperties({ title: "Liste de Colisage" });
  doc.output("pdfobjectnewwindow");
}